<template>
    <div class="app_body" :style="{
        background:headerForm.background,
    }">
        <p style="color:#fff;" v-if="showPlatform">当前环境A：{{checkPlatform()}}</p>
        <!--  -->
        <div class="rule" @click="lookRule" v-if="headerForm.rules">规则</div>
        <!--  -->
        <div class="eq_head">
            <!-- <div class="eq_head"> -->
            <!-- <van-tabs v-model="active" ref="tabs" @click="van_click" background="#F5F5F5" color="#F5F5F5"
                title-inactive-color="#ccc" title-active-color="#C58940" class="selectTab" animated swipeable> -->
            <div style="margin-top: 15px;">
                <van-tabs v-if="MembershipList" :lazy-render="false" v-model="active" @change="changeSelect" ref="tabs"
                    @click="van_click" background="#F5F5F5" color="#F5F5F5" title-inactive-color="#ccc"
                    title-active-color="#C58940" class="selectTab" animated swipeable>
                    <van-tab v-for="(item,index) in MembershipList" :key="index">
                        <template #title style="height: 100%;">
                            <div class="mbcmc_box">
                                <!-- <img v-if="active_bottom == active" class="mbcmcbox_img" :src="item.iconUrl" alt="">
                                <img v-else :src="item.iconUrl" alt=""> -->
                                <img :src="item.iconUrl" alt="" :class="active == index?'iconclor':'iconclor2'">
                                <!-- <img v-if="active_bottom == active" class="{{active_bottom == active?'mbcmcbox_img':''}}" :src="member_img.discount"
                                    alt=""> -->
                                <p style="font-size: 13px;">{{item.name}}</p>
                            </div>
                        </template>
                        <div v-if="item" class="eq_center">
                            <img :src="item.imgUrl" alt="">
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
            <div class="h" style="height: 60px;"></div>
        </div>


        <!-- 立即支付 -->
        <div class="memberFooter" v-if="this.$route.params.typeof">
            <p class="member_pay" @click="Pay_now">立即开通</p>
        </div>

        <!--  -->
        <!-- <van-dialog v-model="showRule">
            <ul style="padding: 10px;">
                <li class="rule_item" v-for="(i,j) in rulesList" :key="j+'a'">{{i}}</li>
            </ul>
        </van-dialog> -->
        <!--  -->
        <!-- <van-overlay :show="show" @click="show = false">
            <div class="overlay_wrapper" @click.stop>
                <img :src="hintImgUrl" alt style="width:100%;" />
                <svg @click.stop="onClose" style="margin-top: 20px;" t="1602309924364" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2440" width="32" height="32">
                    <path d="M512 1016C233.6 1016 8 790.4 8 512S233.6 8 512 8 1016 233.6 1016 512 790.4 1016 512 1016z m0-960C260.8 56 56 260.8 56 512S260.8 968 512 968 968 763.2 968 512 763.2 56 512 56z" fill="#ffffff" p-id="2441" />
                    <path d="M548.8 512l195.2-195.2c9.6-9.6 9.6-25.6 0-36.8l-1.6-1.6c-9.6-9.6-25.6-9.6-35.2 0L512 475.2 316.8 280c-9.6-9.6-25.6-9.6-35.2 0l-1.6 1.6c-9.6 9.6-9.6 25.6 0 36.8L475.2 512 280 707.2c-9.6 9.6-9.6 25.6 0 36.8l1.6 1.6c9.6 9.6 25.6 9.6 35.2 0L512 548.8l195.2 195.2c9.6 9.6 25.6 9.6 35.2 0l1.6-1.6c9.6-9.6 9.6-25.6 0-36.8L548.8 512z" fill="#ffffff" p-id="2442" />
                </svg>
            </div>
        </van-overlay>-->

        <!--  -->
        <div style="position: fixed;right: 10px;bottom: 100px;opacity: 0.7;" @click="topPage" v-show="gotop">
            <img src="../../assets/top.png" style="width: 40px;height: 40px;" alt />
        </div>
    </div>
</template>
<script>
    import { onekeyCollection, getTakeStatusByBadId, getHotRecommendations, getMyCommodityMemberships, getCommodityMemberships } from "@/api/activity";
    // import { Swiper, SwiperSlide } from "vue-awesome-swiper";
    import "swiper/swiper-bundle.css";
    import Swiper2, { Navigation, Pagination, } from "swiper";
    Swiper2.use([Navigation, Pagination,]);

    let recordTime = null;
    export default {
        inject: ["reload"],
        components: {
            // Swiper,
            // SwiperSlide,
        },
        data() {
            return {
                show: false,
                swiperOptions: {
                    effect: "coverflow",
                    slidesPerView: 1,
                    initialSlide: 1,
                    spaceBetween: 30,
                    centeredSlides: true,
                    loop: true,
                    speed: 1000,
                    // 分页器

                    autoplay: {
                        delay: 1000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    },
                    coverflowEffect: {
                        rotate: 20, // slide做3d旋转时Y轴的旋转角度。默认50。
                        stretch: 2, // 每个slide之间的拉伸值，越大slide靠得越紧。
                        depth: 100, // slide的位置深度。值越大z轴距离越远，看起来越小。
                        modifier: 1, // depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
                        slideShadows: false // 开启slide阴影。默认 true。
                    }
                },
                isUndate: true,
                appBodyComponents: [],
                likeList: [],
                headerForm: {
                    name: "",
                    description: "",
                    background: "#F5F5F5",
                    rules: "",
                    shareLogo: ""
                },
                rulesList: [],
                showRule: false,
                showPlatform: false,
                takeStatus: 0,
                hintImgUrl: "",
                pindex: null,
                cindex: null,
                effectImgUrl: null,
                gotop: false,
                my_gotop: false,
                listBoxState: true,//点击导航栏时，暂时停止监听页面滚动
                navgatorIndex: 0,
                textNavigationId: null,
                textNavigation: null,
                showNav: false,
                toplist: [800, 4260, 7929],
                countDownInfo: null,
                countDownText: "00:00:00",
                commodityClickHintText: null,
                list1: [],
                member_img: {
                    headBg: require("../../assets/image/headBg@2x.png"),
                    Alipay: require("../../assets/image/Alipay@2x.png"),
                    allowance: require("../../assets/image/allowance@2x.png"),
                    carefree: require("../../assets/image/carefree@2x.png"),
                    discount: require("../../assets/image/discount@2x.png"),
                    discounts: require("../../assets/image/discounts@2x.png"),
                    ellipse: require("../../assets/image/ellipse@2x.png"),
                    equitTitle: require("../../assets/image/equitTitle@2x.png"),
                    member_check: require("../../assets/image/member_check@2x.png"),
                    menberBtn: require("../../assets/image/menberBtn@2x.png"),
                    payCard: require("../../assets/image/payCard@2x.png"),
                    Pinkage: require("../../assets/image/Pinkage@2x.png"),
                    query: require("../../assets/image/query@2x.png"),
                    service: require("../../assets/image/service@2x.png"),
                    shipments: require("../../assets/image/shipments@2x.png"),
                    wx_chat: require("../../assets/image/wx_chat@2x.png"),
                    likeTitle: require("../../assets/image/likeTitle@2x.png"),
                    memberCard: require("../../assets/image/memberCard@2x.png"),
                    chaoji: require("../../assets/image/chaoji.png"),
                },
                note: {
                    backgroundImage: "url(" + require("../../assets/image/headBg@2x.png") + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100%",
                    marginTop: "5px",
                },
                active_bottom: 0,
                active: 0,
                userId: "",
                memberVip: "",
                MembershipList: "",
                MemberData: "",
                heigthpm: "",
            };
        },
        watch: {
            show(v) {
                if (!v) {
                    const { pindex, cindex, effectImgUrl } = this;
                    if (pindex !== null && cindex !== null) {
                        this.appBodyComponents[pindex].config.imgList[cindex].url = effectImgUrl;
                    }
                }
            },
            showNav(v) {
                if (!v) {
                    this.navgatorIndex = 0;
                }
            }
        },
        methods: {
            /* eslint-disable */

            topPage() {
                let top = document.documentElement.scrollTop || document.body.scrollTop;
                const timeTop = setInterval(() => {
                    document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
                    if (top <= 0) {
                        clearInterval(timeTop);
                    }
                }, 10);
            },
            // 根据礼包id以及用户id获取领取状态
            getTakeStatusByBadId(params) {
                return getTakeStatusByBadId(params).then(res => {
                    if (res.code === "200") {
                        return res.data;
                    } else {
                        this.$toast(res.message);
                    };
                }).catch(err => {
                    this.$toast(err.message);
                });
            },
            // 查看规则
            lookRule() {
                this.showRule = true;
            },
            onClose() {
                this.show = false;
            },

            // 礼包领取
            handleOnekeyCollection(params) {
                onekeyCollection(params).then(res => {
                    if (res.code === "200") {
                        this.effectImgUrl = res.data.effectImgUrl;
                        this.hintImgUrl = res.data.hintImgUrl;
                        this.takeStatus = res.data.takeStatus;
                        this.show = true;
                    } else {
                        this.$toast(res.message);
                    }
                }).catch(err => {
                    this.$toast(err.message);
                })
            },
            // 立即支付
            Pay_now() {
                // this.$router.push({
                //     name: "serveCenter"
                // })
                this.$router.go(-1)
            },
            //猜你喜欢商品详情
            onLookInfo(singleId) {
                window.open(`https://www.weijianapp.com/commodity/?singleId=${singleId}`);
            },
            getShareInfo() {
                const { description, shareLogo, name } = this.headerForm;
                const platform = this.checkPlatform();
                /* eslint-disable */
                switch (platform) {
                    case "WeChat":
                        wx.miniProgram.postMessage(JSON.stringify({
                            eventType: "share", params: {
                                description: description,
                            }
                        }))
                        // wx.miniProgram.postMessage({ data: 'foo' })
                        break;
                    case "browser":
                        window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.weijian.app";
                        break;
                    case "Android":
                        android.postMessage(JSON.stringify({
                            eventType: "share", params: {
                                description: description,
                                shareLogo: shareLogo,
                                name: name
                            }
                        }))
                        break;
                    case "Ios":
                        window.webkit.messageHandlers.call.postMessage(JSON.stringify({
                            eventType: "share", params: {
                                description: description,
                                shareLogo: shareLogo,
                                name: name
                            }
                        }));
                        return JSON.stringify({
                            eventType: "share", params: {
                                description: description,
                                shareLogo: shareLogo,
                                name: name
                            }
                        })
                        break;
                    default:
                        break;
                }
                /* eslint-disable */
            },

            // 猜你喜欢
            getlikeList() {
                // const url = "/mk/app/commoditySingleProduct/getHotRecommendations";
                // const {
                // 	urlKey,
                // 	antiKey
                // } = rsaEncrypt(url);
                // axios
                // 	.post(baseUrl + url, {}, {
                // 		headers: {
                // 			antiKey,
                // 			urlKey,
                // 		},
                // 	})
                // 	.then((res) => {
                // 		if (res.data.code == "200") {
                // 			this.likeList = res.data.data
                // 		}
                // 	})
                getHotRecommendations().then(async res => {
                    if (res.code === "200") {
                        this.likeList = res.data
                        console.log("this.likeList", this.likeList)
                    }
                }).catch(err => {
                    // console.log("err", err.message)
                }).finally(errs => {
                    // console.log("errs", errs.message)
                })
            },

            // },
            /* eslint-disable */
            handleCountDown(end, cb) {
                let nowtime = new Date(); //获取当前时间
                let endtime = new Date(end);//定义结束时间
                let lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
                    leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
                    lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
                    leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
                    lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
                if (lefth < 0 || leftm < 0 || lefts < 0) {
                    clearInterval(recordTime);
                    cb && cb();
                    return "00:00:00";
                }
                const prefixZero = (num, n) => {
                    return (Array(n).join(0) + num).slice(-n);
                };
                // console.log(`${leftd}天${lefth}时${leftm}分${lefts}秒`);
                return (
                    (leftd > 0 ? leftd * 24 + lefth : prefixZero(lefth, 2)) +
                    ":" +
                    prefixZero(leftm, 2) +
                    ":" +
                    prefixZero(lefts, 2)
                );
            },
            startCountDown(endtime) {
                clearInterval(recordTime);
                if (endtime) {
                    endtime = endtime.replace(/-/g, "/");
                    recordTime = setInterval(() => {
                        this.countDownText = this.handleCountDown(endtime, () => {
                            console.log("时间到");
                            this.appBodyComponents.forEach(item => {
                                if (item.template == "commodity") {
                                    item.config.buyButText = this.countDownInfo.btnText;
                                    this.commodityClickHintText = this.countDownInfo.hintText;
                                }
                            });
                        })
                    }, 1000)
                }
            },
            // 判断平台
            checkPlatform() {
                /* eslint-disable */
                function ready() {
                    return window.__wxjs_environment === 'miniprogram'
                }
                var a = navigator.userAgent.toLowerCase(),
                    isWeChat = a.match(/MicroMessenger/i) != null || a.match(/NetType/i) != null || a.match(/Language/i) != null;
                if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
                    document.addEventListener('WeixinJSBridgeReady', ready, false)
                }
                if (window.BoutiquePriceDetail) {
                    return "Android";
                } else if (ready()) {
                    return "WeChatApplets";
                } else if (window.webkit || /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                    return "Ios";
                } else if (/(Android)/i.test(navigator.userAgent)) {
                    return "Android";
                } else {
                    return "browser";
                }
                /* eslint-disable */
            },
            getQueryVariable(variable) {
                var query = window.location.search.substring(1);
                var vars = query.split("&");
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split("=");
                    if (pair[0] == variable) {
                        return pair[1];
                    }
                }
                return false;
            },
            // isUndated() {
            // 	if (this.isUndate) {
            // 		this.$router.go(0)
            // 		this.isUndate = false
            // 	}
            // }

            getMyCommodityMemberships() {
                let params = {
                    userId: this.userId
                }
                getMyCommodityMemberships(params).then(async res => {
                    if (res.code === "200") {
                        this.memberVip = res.data.rights
                    }
                }).catch(err => {
                    // console.log("err", err.message)
                }).finally(errs => {
                    // console.log("errs", errs.message)
                })
            },

            getCommodityMemberships() {
                getCommodityMemberships().then(async res => {
                    if (res.code === "200") {
                        // this.$route.params.slideIndex ? this.$route.params.slideIndex : 0
                        console.log("MembershipList  slideIndex", this.$route.params.slideIndex)
                        this.active = this.$route.params.index


                        let m = this.$route.params.slideIndex


                        this.MembershipList = res.data[m].rights
                        console.log("this.MembershipList", this.MembershipList)

                        console.log("MembershipList  this.active", this.active)

                        // this.MemberData = this.MembershipList[this.active].rights
                    }
                }).catch(err => {
                    // console.log("err", err.message)
                }).finally(errs => {

                    // console.log("errs", errs.message)
                })
            },
            van_click(event) {
                console.log("e", event)
                this.active_bottom = event

            },
            changeSelect(event) {

                this.active_bottom = event
                // this.active = event
            }
        },

        mounted() {
            // const { activityId } = this.$route.params;
            this.userId = this.$route.params.userId || "851840870494765071"
            // window.getShareInfo = this.getShareInfo;
            // const showPlatform = this.getQueryVariable("showPlatform");
            // this.showPlatform = showPlatform;
            // window.addEventListener("scroll", this.handleScroll, true);
            // this.getActivityInfo(activityId);
            // this.getMyCommodityMemberships();
            // this.getCommodityMemberships(this.$route.params.slideIndex)
            // this.getPosition();
        },
        activated() {
            console.log("activated", this.$route.params)

            // this.active = 0
            this.active = this.$route.params.index
            this.MembershipList = this.$route.params.rightList

            this.$forceUpdate() // 强制刷新
            // this.getCommodityMemberships(this.$route.params.slideIndex)

            // this.getCommodityMemberships(this.$route.params.slideIndex)
            window.scrollTo(0, 0)
        },
        deactivated() {
            // this.active = 0
            // console.log("deactivated", this.$route.params)
            // this.active = this.$route.params.index

            // this.getCommodityMemberships(this.$route.params.slideIndex)

        },
        watch: {
            '$route'(val) {
                this.$forceUpdate()
                // this.active = 0
                this.active = val.params.index
            }
        }

    };
</script>

<style lang="scss" scoped>
    body {
        font-family: PingFangSC-Regular, PingFang SC;
    }


    .my-bullet border-radius {
        width: 0.04rem;
        height: .04rem;
        margin: 0 .03rem;
        display: inline-block;
        background: rgba(0, 0, 0, 0.20);
    }

    .my-bullet-active {
        background: #3CDBC0;
        width: 0.16rem;
    }

    .eq_head {
        overflow: hidden;
        padding: 0px 15px;
        padding-right: 0;
        /* margin-top: 20px; */
        /* box-sizing: border-box; */
        /* min-height: 750px; */

        .eq_title {
            display: flex;
            /* justify-content: space-around; */
            /* align-content: flex-start; */
            /* overflow-x: overlay; */
            overflow: hidden;
            flex-wrap: nowrap;
            overflow-x: auto;

            .mbcmc_box {
                width: 100%;
                text-align: center;
                margin-top: 30px;
                margin-right: 26px;
                opacity: 0.1;

                img {
                    width: 46px;
                    height: 46px;
                }

                p {
                    width: 60px;
                    margin-top: 5px;
                    font-size: 13px;
                    color: #C58940;
                }
            }
        }

    }


    .memberFooter {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 100%;
        background: linear-gradient(180deg, #ECBD83 0%, #F8D9AD 100%);
        z-index: 1;
        text-align: center;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #242430;
        font-size: 18px;
        padding: 15px 0;
    }

    .memberFooter .member_pay {
        /* width: 85%;
        text-align: center;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #242430;
        font-size: 18px;
        padding: 12px 0;
        margin: 10px 30px;
        border-radius: 26px; */
    }


    /* .van-tabs--line .van-tabs__wrap {
        height: 144px !important;
    }

    .van-tabs__wrap,
    .van-tabs__wrap scroll-view,
    .van-tabs__nav,
    .van-tab {
        height: 1440px !important;
        background-color: white;
    } */


    .mbcmc_box {
        text-align: center;
        margin-right: 26px;

        img {
            width: 49px;
            height: 49px;
            /* margin-left: 2px; */
            opacity: 0.5;
        }
    }



    .iconclor {
        opacity: 1 !important;
    }


    .eq_center {
        /* text-align: left; */
        text-align: left;
        margin-top: 20px;
        animation: myfirst 2.4s 0s ease both;

        img {
            /* width: 318px; */
            width: 90%;
            /* height: 74vh; */
            transition: height 1s;
            /* width: 200px; */

        }

        /* // animation:myfirst 0.5s 0s ease both; */
        /* @-webkit-keyframes myfirst
            {
            0% {
                left: 0px;
                height: 60vh;
            }

            100% {
                height: 74vh;

            }
        } */
    }

    .selectTab /deep/ .van-tab__pane-wrapper--inactive .eq_center {
        width: 100%;
        margin-left: -29px;
        margin-top: 30px;
    }

    .selectTab /deep/ .van-tab .van-tab--active {
        /* width: 100%; */
        opacity: 1 !important;
    }

    .van-tabs--line /deep/ .van-tabs__wrap {
        /* margin-top: 20px; */
        height: 80px;
    }


    .iconclor2 {}

    .attribute {
        opacity: 0.1;
    }

    .van-tab:last-child .van-tab__text .mbcmc_box {
        margin-right: 0;
    }

    .mbcmcbox_img {
        opacity: 0.4;
    }

    .van-tab__text--ellipsis .selectTab /deep/ .van-tabs__wrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .selectTab /deep/ .van-tabs__nav--line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 78px;
    }

    .selectTab /deep/ .van-tab__text--ellipsis {
        overflow: auto;
        display: block;
        opacity: 1;
    }

    .selectTab /deep/ .van-tabs__nav--complete {
        padding-left: 0 !important;
        display: flex;
        justify-content: space-between;

    }

    .selectTab /deep/ .van-tab {
        /* width: 100%; */
        display: flex;
        justify-content: space-between;
        flex: none;
    }


    div /deep/ .van-tab {
        padding: 0;
    }


    #cnzz_stat_icon_1279074062 {
        opacity: 0;
        position: relative;
        left: -100px;
    }
</style>