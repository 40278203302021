<template>
	<div class="app_body" :style="{
		background:headerForm.background
	}">
		<div class="activity_member" v-if="isMember== true">
			<p style="color:#fff;" v-if="showPlatform">当前环境A：{{checkPlatform()}}</p>
			<!--  -->
			<!--  -->
			<div class="memberbox" ref="element">
				<div class="memberHead" :style="note">
					<Swiper class="swiper-container" :options="swiperOptions" ref="mySwiper" @slideChange="slideChange">
						<SwiperSlide style="margin-right: -20px;" v-for="(item,index) in MembershipList" :key="index">
							<div class="mbhi cube">
								<div class="mbhimages">
									<img class="mbhimages_img" :src="item.backgroundImage" alt="">
									<div class="mbh_center">
										<div class="mb_title">
											{{item.name}}
										</div>
										<p class="mb_centertitle">
											{{item.centerRemark}}
										</p>
										<div class="mbh_btn">
											<img :src="member_img.menberBtn3" @click="Pay_now(item)" alt="">
											<span class=" mbh_spaceBox">
												<span class="mbh_space">￥{{item.price}}/月</span>
												<span class="mbh_discount">原价 <span
														class="mbh_discounts">￥{{item.markingPrice}}</span></span>
											</span>
											<!-- <span class="mbh_Pay" @click="Pay_now(item)">立即购买</span> -->
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
					</Swiper>
				</div>
			</div>

			<div class="memberCenter">
				<div class="mbc_title">
					<img :src="member_img.equitTitle" alt="">
				</div>
				<div class="mbc_Member" v-if="memberData.rights && memberData.rights.length != 0">
					<div class="mbcm_title">
						<p>黑卡会员</p>
						<p @click="menber_rule">详细规则></p>
					</div>
					<div class="mbcm_center"
						v-if="memberData.rights.length == 8 || memberData.rights.length == 12 || memberData.rights.length == 4">
						<!-- <div class="mbcm_center"> -->
						<div class="mbcmc_bigBox" v-if="memberDataF.length != 0">
							<template v-for="(item,index) in memberDataF">
								<div class="mbcmc_box" :key="index" @click="member_equity(item,index,0)">
									<img :src="item.iconUrl" alt="">
									<p>{{item.name}}</p>
								</div>
							</template>
						</div>
						<div class="mbcmc_bigBox" v-if="memberDataS.length != 0">
							<template v-for="(item,index) in memberDataS">
								<div class="mbcmc_box" :key="index" @click="member_equity(item,4+index,1)">
									<img :src="item.iconUrl" alt="">
									<p>{{item.name}}</p>
								</div>
							</template>
						</div>
						<div class="mbcmc_bigBox" v-if="memberDataT.length != 0">
							<template v-for="(item,index) in memberDataT">
								<div class="mbcmc_box" :key="index" @click="member_equity(item,8+index,1)">
									<img :src="item.iconUrl" alt="">
									<p>{{item.name}}</p>
								</div>
							</template>
						</div>
					</div>

					<div class="mbcm_center"
						v-else-if="memberData.rights.length == 9 || memberData.rights.length == 3|| memberData.rights.length == 6">
						<div class="mbcmc_bigBox" v-if="memberDataF.length != 0">
							<template v-for="(item,index) in memberDataF">
								<div class="mbcmc_box" :key="index" @click="member_equity(item,index,0)">
									<img :src="item.iconUrl" alt="">
									<p>{{item.name}}</p>
								</div>
							</template>
						</div>
						<div class="mbcmc_bigBox" v-if="memberDataS.length != 0">
							<template v-for="(item,index) in memberDataS">
								<div class="mbcmc_box" :key="index" @click="member_equity(item,3+index,1)">
									<img :src="item.iconUrl" alt="">
									<p>{{item.name}}</p>
								</div>
							</template>
						</div>
						<div class="mbcmc_bigBox" v-if="memberDataT.length != 0">
							<template v-for="(item,index) in memberDataT">
								<div class="mbcmc_box" :key="index" @click="member_equity(item,6+index,1)">
									<img :src="item.iconUrl" alt="">
									<p>{{item.name}}</p>
								</div>
							</template>
						</div>
					</div>

					<div class="mbcm_center"
						v-else-if="memberData.rights.length == 11 || memberData.rights.length == 10">
						<div class="mbcmc_bigBox" v-if="memberDataF.length != 0">
							<template v-for="(item,index) in memberDataF">
								<div class="mbcmc_box" :key="index" @click="member_equity(item,index,0)">
									<img :src="item.iconUrl" alt="">
									<p>{{item.name}}</p>
								</div>
							</template>
						</div>
						<div class="mbcmc_bigBox" v-if="memberDataS.length != 0">
							<template v-for="(item,index) in memberDataS">
								<div class="mbcmc_box" :key="index" @click="member_equity(item,4+index,1)">
									<img :src="item.iconUrl" alt="">
									<p>{{item.name}}</p>
								</div>
							</template>
						</div>
						<div class="mbcmc_bigBox2" v-if="memberDataT.length != 0">
							<template v-for="(item,index) in memberDataT">
								<div class="mbcmc_box2" :key="index" @click="member_equity(item,8+index,1)">
									<img :src="item.iconUrl" alt="">
									<p>{{item.name}}</p>
								</div>
							</template>
							<template v-for="(item,index) in 4-memberDataT.length">
								<div class="mbcmc_box2" :key="index">
									<div class="mbcmc_bocc"></div>
									<p></p>
								</div>
							</template>
						</div>
					</div>

					<div class="mbcm_center"
						v-else-if="memberData.rights.length == 7 || memberData.rights.length == 5 ">
						<div class="mbcmc_bigBox" v-if="memberDataF.length != 0">
							<template v-for="(item,index) in memberDataF">
								<div class="mbcmc_box" :key="index" @click="member_equity(item,index,0)">
									<img :src="item.iconUrl" alt="">
									<p>{{item.name}}</p>
								</div>
							</template>
						</div>
						<div class="mbcmc_bigBox2" v-if="memberDataS.length != 0">
							<template v-for="(item,index) in memberDataS">
								<div class="mbcmc_box2" :key="index" @click="member_equity(item,4+index,1)">
									<img :src="item.iconUrl" alt="">
									<p>{{item.name}}</p>
								</div>
							</template>
							<template v-for="(item,index) in 4-memberDataS.length">
								<div class="mbcmc_box2" :key="index">
									<div class="mbcmc_bocc"></div>
									<p></p>
								</div>
							</template>
						</div>
					</div>

					<div class="mbcm_center" v-else>
						<div class="mbcmc_bigBox2" v-if="memberDatAll.length != 0">
							<template v-for="(item,index) in memberDatAll">
								<div class="mbcmc_box2" :key="index" @click="member_equity(item,index,0)">
									<img :src="item.iconUrl" alt="">
									<p>{{item.name}}</p>
								</div>
							</template>
							<template v-for="(item,index) in 4-memberDatAll.length">
								<div class="mbcmc_box2" :key="index">
									<div class="mbcmc_bocc"></div>
									<p></p>
								</div>
							</template>
						</div>
					</div>


				</div>
				<div class="mbc_git">
					<div class="mbc_gitfTitle">开卡大礼包</div>
					<div class="mbcg_centerTitle">成本价再减 开卡即回本</div>
					<div class="mbcg_Card">
						<div class="mbcg_Cardmain" v-for="(item,index) in memberData.coupons" :key="index">
							<template v-if="item.type == 1 || item.type == 2">
								<img :src="member_img.payCard" alt="">
								<div class="mbcg_cmRight">
									<p class="cmRght_top"><span>￥</span>{{item.usedAmount}}</p>
									<p class="cmRght_footer" v-if="item.type == 1">无门槛<span
											style="margin: 0 1px;">·</span>全平台商品
									</p>
									<p class="cmRght_footer" v-if="item.type == 2">
										满{{item.withAmount}}元减{{item.usedAmount}}元
									</p>
								</div>
								<div class="mbcg_cmLeft"><span>开卡领券</span></div>
							</template>
							<template v-if="item.type == 3">
								<img :src="member_img.payCard" alt="">
								<div class="mbcg_cmRight">
									<p class="cmRght_top2">{{item.title}}</p>
									<p class="cmRght_footer">美妆商品
									</p>
								</div>
								<div class="mbcg_cmLeft"><span>开卡领券</span></div>
							</template>
						</div>
						<!-- <div class="mbcg_Cardmain">
							<img :src="member_img.payCard" alt="">
							<div class="mbcg_cmRight">
								<p class="cmRght_top"><span>￥</span>30</p>
								<p class="cmRght_footer">无门槛 <span>·</span> 全平台商品</p>
							</div>
							<div class="mbcg_cmLeft"> <span>开卡领券</span> </div>
						</div>
						<div class="mbcg_Cardmain">
							<img :src="member_img.payCard" alt="">
							<div class="mbcg_cmRight">
								<p class="cmRght_top"><span>￥</span>30</p>
								<p class="cmRght_footer">无门槛 <span>·</span> 全平台商品</p>
							</div>
							<div class="mbcg_cmLeft"> <span>开卡领券</span> </div>
						</div> -->
					</div>
				</div>
				<div class="mbc_price">
					<p>
						<span>原价</span>
						<span>￥{{memberData.markingPrice}}</span>
					</p>
					<p v-if="memberData.favorablePrice">
						<span>已优惠</span>
						<!-- <span>￥{{ (memberData.markingPrice - memberData.price).toFixed(2)}}</span> -->
						<span>￥{{memberData.favorablePrice}}</span>
					</p>
				</div>
				<div class="mbc_footer">
					<p class="mbcf_title">请选择支付方式</p>
					<div class="mbcf_alipay" @click="Pay_check(0)">
						<p>
							<img :src="member_img.Alipay" alt="">
							<span>支付宝支付</span>
						</p>
						<p>
							<img :src="isPay == 0?member_img.member_check:member_img.ellipse" alt="">
						</p>
					</div>
					<div class="mbcf_alipay2" @click="Pay_check(1)">
						<p>
							<img :src="member_img.wx_chat" alt="">
							<span>微信支付</span>
						</p>
						<p>
							<img :src="isPay == 1?member_img.member_check:member_img.ellipse" alt="">
						</p>
					</div>
				</div>
			</div>

			<!-- 立即支付 -->
			<div class="memberFooter">
				<p class="member_pay" @click="Pay_now(memberData)">￥{{memberData.price}}立即支付</p>
			</div>

			<div class="like_listTitle">
				<img :src="member_img.likeTitle" alt="">
			</div>
			<!-- 猜你喜欢 -->
			<ul class="like_list">

				<li class="like_item_warp" v-for="(item,index) in likeList" :id="item.singleId" :key="`hq_${index}`"
					@click="onClickCommodity(item)">
					<div class="like_item">
						<div class="like_item-imgbox">
							<img :src="item.imgUrl" class="like_item-img">
						</div>
						<div class="like_item-info">
							<p class="like_item-name">{{`${item.brandName} ${item.commodityName}
								${item.standardValue}`}}</p>
							<p class="like_item-people">
								<span class="like_item-price">
									<span class="rmb">￥</span>
									<span>{{item.price ||'--'}}</span>
									<span class="lick_black" v-if="item.blackPrice">黑卡</span>
									<span class="rmb2">￥</span>
									<span class="black_rmb">{{item.blackPrice}}</span>
								</span>
							</p>
							<p class="like_item-play">{{item.payPersons}}人付款</p>
						</div>
					</div>
				</li>
			</ul>

			<!-- <ul v-if="textNavigation && showNav" class="nav_list navfix" :style="{
						paddingRight:0,
						'overflow-x':textNavigation.config.isSlide==1?'scroll':'hidden',
						background:textNavigation.config.style.background
						}" ref="navlist">
				<li ref="navitem" @click="goscrol(navItem.link,navIndex,$event)" class="nav_item-warp nav_item-textWarp" :style="{
								width:textNavigation.config.isSlide==0?`calc(100% / ${textNavigation.config.navList.length})`:'108px'
							}" v-for="(navItem,navIndex) in textNavigation.config.navList" :key="`navconfig_${navIndex}`">
					<div class="nav_title">
						<span class="nav_title_text" :style="{color:navIndex==navgatorIndex?'#ff5a5a':textNavigation.config.style.color}">
							{{navItem.title}}
							<i v-show="navIndex==navgatorIndex" class="nav_title_line" />
						</span>
					</div>
				</li>
			</ul>-->
			<!--  -->
			<van-dialog v-model="showRule">
				<ul style="padding: 10px;">
					<li class="rule_item" v-for="(i,j) in rulesList" :key="j+'a'">{{i}}</li>
				</ul>
			</van-dialog>
			<!--  -->
			<!-- <van-overlay :show="show" @click="show = false">
				<div class="overlay_wrapper" @click.stop>
					<img :src="hintImgUrl" alt style="width:100%;" />
					<svg @click.stop="onClose" style="margin-top: 20px;" t="1602309924364" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2440" width="32" height="32">
						<path d="M512 1016C233.6 1016 8 790.4 8 512S233.6 8 512 8 1016 233.6 1016 512 790.4 1016 512 1016z m0-960C260.8 56 56 260.8 56 512S260.8 968 512 968 968 763.2 968 512 763.2 56 512 56z" fill="#ffffff" p-id="2441" />
						<path d="M548.8 512l195.2-195.2c9.6-9.6 9.6-25.6 0-36.8l-1.6-1.6c-9.6-9.6-25.6-9.6-35.2 0L512 475.2 316.8 280c-9.6-9.6-25.6-9.6-35.2 0l-1.6 1.6c-9.6 9.6-9.6 25.6 0 36.8L475.2 512 280 707.2c-9.6 9.6-9.6 25.6 0 36.8l1.6 1.6c9.6 9.6 25.6 9.6 35.2 0L512 548.8l195.2 195.2c9.6 9.6 25.6 9.6 35.2 0l1.6-1.6c9.6-9.6 9.6-25.6 0-36.8L548.8 512z" fill="#ffffff" p-id="2442" />
					</svg>
				</div>
			</van-overlay>-->
			<!--  -->

			<div style="position: fixed;right: 10px;bottom: 100px;opacity: 0.7;" @click="topPage" v-show="gotop">
				<img src="../../assets/top.png" style="width: 40px;height: 40px;" alt />
			</div>
		</div>

		<div class="app_body_box2" v-if="isMember== false">
			<img :src="member_img.Upgrade" alt="">
			<p>会员服务升级中 </p>
			<p>请耐心等待～</p>
		</div>


	</div>

</template>
<script>
	import { getActivityInfo, onekeyCollection, getTakeStatusByBadId, getHotRecommendations, getCommodityMemberships } from "@/api/activity";
	import { Swiper, SwiperSlide } from "vue-awesome-swiper";
	import "swiper/swiper-bundle.css";
	let recordTime = null;
	export default {
		inject: ["reload"],
		components: {
			Swiper,
			SwiperSlide,
		},
		data() {
			return {
				show: false,
				// swiperOptions: {
				// 	effect: "coverflow",
				// 	slidesPerView: 1.4,
				// 	spaceBetween: 30,
				// 	centeredSlides: true,
				// 	loop: false,
				// 	speed: 1000,
				// 	autoplay: {
				// 		delay: 1000,
				// 		stopOnLastSlide: false,
				// 		disableOnInteraction: false
				// 	},
				// 	coverflowEffect: {
				// 		rotate: 20, // slide做3d旋转时Y轴的旋转角度。默认50。
				// 		stretch: 2, // 每个slide之间的拉伸值，越大slide靠得越紧。
				// 		depth: 100, // slide的位置深度。值越大z轴距离越远，看起来越小。
				// 		modifier: 1, // depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
				// 		slideShadows: false // 开启slide阴影。默认 true。
				// 	},
				// 	on: {
				// 		// 使用es6的箭头函数，使this指向vue对象
				// 		click: () => {
				// 			// 通过$refs获取对应的swiper对象
				// 			let swiper = this.$refs.mySwiper.swiper;
				// 			// let i = swiper.activeIndex;
				// 			console.log(swiper);
				// 			// let flag = this.imgList[i];
				// 			// location.href = flag.url;
				// 		}

				// 	}
				// },

				swiperOptions: {
					// el: ".swiper-pagination",
					// runCallbacksOnInit: true,
					// effect: "coverflow",
					initialSlide: 1, // 默认显示下标，自行设置
					centeredSlides: true, // 居中显示
					// swiper动态加载
					observer: true,
					observeParents: true,
					// 第一张和最后一张贴合边缘 
					// centeredSlidesBounds: true,
					shortSwipes: true,  //短切换
					longSwipes: true,
					// slidesPerView: "Auto",
					slidesPerView: 1.4,
					grabCursor: true,
					// touchRatio: 2,
					spaceBetween: 25,
					longSwipesRatio: 0.1,  //拖动比例
					freeMode: true,
					freeModeSticky: true,
					// freeModeMomentumVelocityRatio: 1,
					// freeModeMomentumBounceRatio: 4,  //反弹效果
					freeModeMomentumBounce: true,
					// passiveListeners: true,
					// loopAdditionalSlides: 100,
					// slidesPerGroup: 2,
					loop: false,
					speed: 100,
					on: {
						slideChangeTransitionEnd: function () {
							this.activeIndex = 1;
						},
					},
				},
				// swiperOptions: {},


				isUndate: true,
				appBodyComponents: [],
				likeList: [],
				headerForm: {
					name: "",
					description: "",
					background: "#242430",
					rules: "",
					shareLogo: ""
				},
				rulesList: [],
				showRule: false,
				showPlatform: false,
				takeStatus: 0,
				hintImgUrl: "",
				pindex: null,
				cindex: null,
				effectImgUrl: null,
				gotop: false,
				my_gotop: false,
				listBoxState: true,//点击导航栏时，暂时停止监听页面滚动
				navgatorIndex: 0,
				textNavigationId: null,
				textNavigation: null,
				showNav: false,
				toplist: [800, 4260, 7929],
				countDownInfo: null,
				countDownText: "00:00:00",
				commodityClickHintText: null,
				list1: [],
				member_img: {
					headBg: require("../../assets/image/headBg@2x.png"),
					Alipay: require("../../assets/image/Alipay@2x.png"),
					allowance: require("../../assets/image/allowance@2x.png"),
					carefree: require("../../assets/image/carefree@2x.png"),
					discount: require("../../assets/image/discount@2x.png"),
					discounts: require("../../assets/image/discounts@2x.png"),
					ellipse: require("../../assets/image/ellipse@2x.png"),
					equitTitle: require("../../assets/image/equitTitle@2x.png"),
					member_check: require("../../assets/image/member_check@2x.png"),

					menberBtn: require("../../assets/image/menberBtn@2x.png"),
					menberBtn2: require("../../assets/image/menberBtn.png"),
					menberBtn3: require("../../assets/image/serve_liji.png"),

					// menberBtn: require("../../assets/image/payCard@2x.png"),

					payCard: require("../../assets/image/payCard@2x.png"),
					Pinkage: require("../../assets/image/Pinkage@2x.png"),
					query: require("../../assets/image/query@2x.png"),
					service: require("../../assets/image/service@2x.png"),
					shipments: require("../../assets/image/shipments@2x.png"),
					wx_chat: require("../../assets/image/wx_chat@2x.png"),
					likeTitle: require("../../assets/image/likeTitle@2x.png"),
					memberCard: require("../../assets/image/memberCard@2x.png"),
					Upgrade: require("../../assets/image/Upgrade.png"),
				},
				note: {
					backgroundImage: "url(" + require("../../assets/image/headBg@2x.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundSize: "100% 248px",
					// marginTop: "5px",
				},
				isPay: 0,
				MembershipList: [],
				memberData: "",
				memberDataF: "",
				memberDataS: "",
				memberDataT: "",
				isMember: true,
				slideChangeData: 0,

			};
		},
		watch: {
			show(v) {
				if (!v) {
					const { pindex, cindex, effectImgUrl } = this;
					if (pindex !== null && cindex !== null) {
						this.appBodyComponents[pindex].config.imgList[cindex].url = effectImgUrl;
					}
				}
			},
			showNav(v) {
				if (!v) {
					this.navgatorIndex = 0;
				}
			}
		},
		methods: {
			/* eslint-disable */
			slideChange(enter) {

				console.log('slideTo发生')
				this.bindSlideChange(enter.activeIndex)

				// })

			},
			bindSlideChange(index) {
				this.slideChangeData = index
				// console.log("this.memberData ", this.memberData.rights)
				// this.$nextTick(() => {
				this.memberData = this.MembershipList[index]

				console.log("this.memberData.rights", this.memberData.rights)

				if (this.memberData.rights.length == 8 || this.memberData.rights.length == 12 || this.memberData.rights.length == 4) {
					this.memberDataF = this.memberData.rights.slice(0, 4)
					this.memberDataS = this.memberData.rights.slice(4, 8)
					this.memberDataT = this.memberData.rights.slice(8, 12)
					console.log("thismemberDataT", this.memberDataT)
				} else if (this.memberData.rights.length == 9 || this.memberData.rights.length == 6 || this.memberData.rights.length == 3) {
					this.memberDataF = this.memberData.rights.slice(0, 3)
					this.memberDataS = this.memberData.rights.slice(3, 6)
					this.memberDataT = this.memberData.rights.slice(6, 9)
				} else if (this.memberData.rights.length == 7 || this.memberData.rights.length == 5) {
					this.memberDataF = this.memberData.rights.slice(0, 4)
					this.memberDataS = this.memberData.rights.slice(4, 8)
				} else if (this.memberData.rights.length == 11 || this.memberData.rights.length == 10) {
					this.memberDataF = this.memberData.rights.slice(0, 4)
					this.memberDataS = this.memberData.rights.slice(4, 8)
					this.memberDataT = this.memberData.rights.slice(8, 12)
				} else {
					this.memberDatAll = this.memberData.rights
				}
			},

			topPage() {
				let top = document.documentElement.scrollTop || document.body.scrollTop;
				const timeTop = setInterval(() => {
					document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
					if (top <= 0) {
						clearInterval(timeTop);
					}
				}, 10);
			},
			// 根据礼包id以及用户id获取领取状态
			getTakeStatusByBadId(params) {
				return getTakeStatusByBadId(params).then(res => {
					if (res.code === "200") {
						return res.data;
					} else {
						this.$toast(res.message);
					};
				}).catch(err => {
					this.$toast(err.message);
				});
			},
			// 查看规则
			lookRule() {
				this.showRule = true;
			},
			onClose() {
				this.show = false;
			},
			// 点击广告图片

			// 礼包领取
			handleOnekeyCollection(params) {
				onekeyCollection(params).then(res => {
					if (res.code === "200") {
						this.effectImgUrl = res.data.effectImgUrl;
						this.hintImgUrl = res.data.hintImgUrl;
						this.takeStatus = res.data.takeStatus;
						this.show = true;
					} else {
						this.$toast(res.message);
					}
				}).catch(err => {
					this.$toast(err.message);
				})
			},
			// 支付方式
			Pay_check(i) {
				this.isPay = i
			},
			menber_rule() {
				this.$router.push({
					name: "agreement"
				})
			},
			member_equity(item, index, i) {
				// console.log("index", index)
				// if (i == 0) {
				// 	index = index
				// } else {
				// 	index = index + 4
				// }

				this.$router.push({
					name: "equity",
					params: {
						index: index,
						slideIndex: this.slideChangeData,
						rightList: this.memberData.rights,
						typeof: 1

					}
				})
			},

			// 立即支付
			Pay_now(item) {
				// this.$router.push({
				// 	name: "serveCenter"
				// })
				const platform = this.checkPlatform();
				const eventTypeList = ["h5", "commodity", "coupon"];
				// const userId = this.getQueryVariable("userId");

				switch (platform) {
					case "WeChatApplets":
						wx.miniProgram.navigateTo({
							url: "/pages/login_page/index"
						})
						// wx.miniProgram.postMessage({
						// 		data: {
						// 			eventType: item.linkType,
						// 			params: {
						// 				id: item.link
						// 			}
						// 		}
						// 	})
						break;
					case "browser":
						window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.weijian.app";
						break;
					case "Android":
						try {
							android.postMessage(JSON.stringify({
								eventType: "member", params: {
									memberType: this.isPay,
									payPrice: item.price,
									shipId: item.shipId,
								}
							}))
						} catch (error) {
							window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.weijian.app";
						}
						break;
					case "Ios":
						// if (item.linkType == 3 && item.link) {
						// 	// 未领取
						// 	if (userId && this.takeStatus === 0) {
						// 		this.handleOnekeyCollection({ userId, bagId: item.link })
						// 		// 已领取
						// 	} if (userId && this.takeStatus === 1) {
						// 		this.$toast("您已领取优惠券，不可重复领取~");
						// 		// 没有userid
						// 	} else if (!userId) {
						// 		// this.$toast("小主，需要更新维鉴App领取优惠劵~");
						// 		try {
						// 			window.webkit.messageHandlers.call.postMessage(JSON.stringify({
						// 				eventType: "login", params: {
						// 					id: null
						// 				}
						// 			}));
						// 		} catch (error) {
						// 			window.location.href = "https://ios.weijianapp.com/app"
						// 		}
						// 	}
						// 	return;
						// }
						try {
							window.webkit.messageHandlers.call.postMessage(JSON.stringify({
								eventType: "member", params: {
									memberType: this.isPay,
									payPrice: item.price,
									shipId: item.shipId,
								}
							}));
						} catch (error) {
							window.location.href = `https://ios.weijianapp.com/app/?singleId=${item.shipId}`
						}
						break;
					default:
						break;
				}
			},

			//猜你喜欢商品详情
			onLookInfo(singleId) {
				window.open(`https://www.weijianapp.com/commodity/?singleId=${singleId}`);
			},
			// 点击商品详情
			onClickCommodity(item) {
				console.log(item);
				const platform = this.checkPlatform();
				console.log(platform);
				/* eslint-disable */
				// if (item.stock == 0 || item.stock == null) {
				// 	return this.$toast("商品已售光");
				// }
				// 如果有倒计时结束后
				if (this.commodityClickHintText) {
					return this.$toast(this.commodityClickHintText || "");
				}
				switch (platform) {
					case "WeChatApplets":
						wx.miniProgram.navigateTo({
							url: "/pages/commodity_details_page/index?singleId=" + item.singleId
						})
						break;
					case "browser":
						window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.weijian.app";
						// https://ios.weijianapp.com/app/?singleId=${this.getQueryVariable("singleId")}&commodityId=${commodityId}					
						break;
					case "Android":
						try {
							android.postMessage(JSON.stringify({
								eventType: "commodity", params: {
									id: item.singleId
								}
							}))
						} catch (error) {
							window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.weijian.app";
						}
						break;
					case "Ios":

						try {
							// window.webkit.messageHandlers.call.postMessage(JSON.stringify({
							// 	eventType: "commodity", params: {
							// 		id: item.singleId
							// 	}
							// }));
							window.webkit.messageHandlers.call.postMessage(JSON.stringify({
								eventType: "commodity", params: {
									id: item.singleId
								}
							}));
						} catch (error) {
							window.location.href = `https://ios.weijianapp.com/app/?singleId=${item.singleId}`
						}
						break;
					default:
						break;
				}
				/* eslint-disable */
			},
			getShareInfo() {
				const { description, shareLogo, name } = this.headerForm;
				const platform = this.checkPlatform();
				/* eslint-disable */
				switch (platform) {
					case "WeChat":
						wx.miniProgram.postMessage(JSON.stringify({
							eventType: "share", params: {
								description: description,
							}
						}))
						// wx.miniProgram.postMessage({ data: 'foo' })
						break;
					case "browser":
						window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.weijian.app";
						break;
					case "Android":
						android.postMessage(JSON.stringify({
							eventType: "share", params: {
								description: description,
								shareLogo: shareLogo,
								name: name
							}
						}))
						break;
					case "Ios":
						window.webkit.messageHandlers.call.postMessage(JSON.stringify({
							eventType: "share", params: {
								description: description,
								shareLogo: shareLogo,
								name: name
							}
						}));
						return JSON.stringify({
							eventType: "share", params: {
								description: description,
								shareLogo: shareLogo,
								name: name
							}
						})
						break;
					default:
						break;
				}
				/* eslint-disable */
			},

			// 猜你喜欢
			getlikeList() {
				getHotRecommendations().then(async res => {
					if (res.code === "200") {
						this.likeList = res.data
						console.log("this.likeList", this.likeList)
					}
				}).catch(err => {
					// console.log("err", err.message)
				}).finally(errs => {
					// console.log("errs", errs.message)
				})
			},
			// 获取详情
			getActivityInfo(activityId) {
				const userId = this.getQueryVariable("userId");
				const _this = this;
				getActivityInfo({ activityId }).then(async res => {
					console.log(res)
					// eslint-disable-next-line no-empty
					if (res.code === "200") {
						const { background, data, description, name, rules, shareLogo } = res.data;
						let newdata = JSON.parse(data);
						let pindex = null;
						let cindex = null;
						let obj = null;
						newdata.forEach((i, index) => {
							if (i.template === "pictureAd") {
								i.config.imgList && i.config.imgList.forEach((j, jindex) => {
									if (j.linkType === 3 && j.link && userId) {
										pindex = index;
										cindex = jindex;
										obj = { bagId: j.link, userId };
									}
								});
							}
							// 
							if (i.template === "textNavigation") {
								_this.textNavigation = i;
								_this.textNavigationId = i.id;
							}
							if (i.template === "countDown") {
								_this.countDownInfo = i.config;
							}
						});
						if (this.countDownInfo) {
							this.startCountDown(this.countDownInfo.time);
						}
						if (obj !== null) {
							let { takeStatus, effectImgUrl, hintImgUrl } = await this.getTakeStatusByBadId(obj);
							this.takeStatus = takeStatus;
							this.hintImgUrl = hintImgUrl;
							if (pindex !== null && cindex !== null && takeStatus === 1) {
								newdata[pindex].config.imgList[cindex].url = effectImgUrl;
							}
							// console.log(newdata, obj, pindex, cindex, effectImgUrl);
						}
						this.appBodyComponents = newdata;

						this.headerForm.name = name;
						this.headerForm.description = description;
						this.headerForm.background = background;
						this.headerForm.rules = rules;
						this.headerForm.shareLogo = shareLogo;
						document.title = name || "活动详情";
						this.rulesList = rules && rules.split(/[\n,]/g);

						// eslint-disable-next-line no-empty
						// setTimeout(this.aaa(), 3000)
					} else {
						console.log(res.message)

					}
				}).catch(err => {
					console.log(res.message)

				}).finally(() => {
					// this.getPosition()
					// this.bbb()
					// 获取商品坐标
					// if (_this.textNavigation) {
					// 	_this.$nextTick(() => {
					// 		let arr = _this.textNavigation.config.navList.map(i => i.link);
					// 		let toplist = [];
					// 		for (let c of arr) {
					// 			const navDom = document.getElementById(c);
					// 			toplist.push(navDom && navDom.offsetTop);
					// 			console.log(navDom)
					// 			console.log(navDom.offsetTop) //节点坐标
					// 		}
					// 		_this.toplist = toplist;
					// 		console.log(toplist)
					// 		console.log(_this.textNavigation)
					// 	})
					// }
				});
			},

			// getPosition() { //获取坐标
			// 	setTimeout(() => {
			// 		if (this.textNavigation) {
			// 			console.log("12321")
			// 			this.$nextTick(() => {
			// 				let arr = this.textNavigation.config.navList.map(i => i.link);
			// 				let toplist = [];
			// 				for (let c of arr) {
			// 					const navDom = document.getElementById(c);
			// 					toplist.push(navDom && navDom.offsetTop);
			// 					console.log(navDom)
			// 					console.log(navDom.offsetTop) //节点坐标
			// 				}
			// 				this.list1 = toplist

			// 				this.toplist = toplist;
			// 				console.log("toplist", toplist)
			// 				console.log("toplist", this.textNavigation)
			// 			})
			// 		}
			// 	}, 500)


			// },
			/* eslint-disable */
			handleCountDown(end, cb) {
				let nowtime = new Date(); //获取当前时间
				let endtime = new Date(end);//定义结束时间
				let lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
					leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
					lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
					leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
					lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
				if (lefth < 0 || leftm < 0 || lefts < 0) {
					clearInterval(recordTime);
					cb && cb();
					return "00:00:00";
				}
				const prefixZero = (num, n) => {
					return (Array(n).join(0) + num).slice(-n);
				};
				// console.log(`${leftd}天${lefth}时${leftm}分${lefts}秒`);
				return (
					(leftd > 0 ? leftd * 24 + lefth : prefixZero(lefth, 2)) +
					":" +
					prefixZero(leftm, 2) +
					":" +
					prefixZero(lefts, 2)
				);
			},
			startCountDown(endtime) {
				clearInterval(recordTime);
				if (endtime) {
					endtime = endtime.replace(/-/g, "/");
					recordTime = setInterval(() => {
						this.countDownText = this.handleCountDown(endtime, () => {
							console.log("时间到");
							this.appBodyComponents.forEach(item => {
								if (item.template == "commodity") {
									item.config.buyButText = this.countDownInfo.btnText;
									this.commodityClickHintText = this.countDownInfo.hintText;
								}
							});
						})
					}, 1000)
				}
			},
			// 判断平台
			checkPlatform() {
				/* eslint-disable */
				function ready() {
					return window.__wxjs_environment === 'miniprogram'
				}
				var a = navigator.userAgent.toLowerCase(),
					isWeChat = a.match(/MicroMessenger/i) != null || a.match(/NetType/i) != null || a.match(/Language/i) != null;
				if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
					document.addEventListener('WeixinJSBridgeReady', ready, false)
				}
				if (window.BoutiquePriceDetail) {
					return "Android";
				} else if (ready()) {
					return "WeChatApplets";
				} else if (window.webkit || /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
					return "Ios";
				} else if (/(Android)/i.test(navigator.userAgent)) {
					return "Android";
				} else {
					return "browser";
				}
				/* eslint-disable */
			},
			getQueryVariable(variable) {

				var query = window.location.search.substring(1);
				var vars = query.split("&");
				for (var i = 0; i < vars.length; i++) {
					var pair = vars[i].split("=");
					if (pair[0] == variable) {
						return pair[1];
					}
				}
				return false;
			},
			getCommodityMemberships() {
				getCommodityMemberships().then(async res => {
					if (res.code === "200") {
						this.MembershipList = res.data
						// 进来的时候，swiper滑到对应位置
						const shipId = this.getQueryVariable("shipId");
						var index2 = 0
						this.MembershipList.forEach((item, index) => {
							if (shipId == item.shipId) {
								return index2 = index
							}
						})
						setTimeout(() => {
							this.swiper.slideTo(index2, 5, false)
							this.slideChange({ activeIndex: index2 })
						}, 100);
						console.log('membership', res.data)
						for (var j = 0; j < this.MembershipList.length; j++) {
							this.memberData = res.data[j]
							let rightcoupons = res.data[j].coupons
							// console.log("rightcoupons1", rightcoupons)
							for (var i = 0; i < this.memberData.onlineCoupon; i++) {
								let online = {
									type: 3,
									title: "免费鉴别券",
								}
								rightcoupons.push(online)
							}
							for (var i = 0; i < this.memberData.fastCoupon; i++) {
								let online = {
									type: 3,
									title: "极速鉴别券",
								}
								rightcoupons.push(online)
							}
							// this.memberData[j].coupons = rightcoupons
							// console.log("rightcoupons", rightcoupons)
						}

						this.memberData = res.data[0]
						// console.log("this.memberData", this.memberData)

						if (this.memberData.rights.length == 8 || this.memberData.rights.length == 12 || this.memberData.rights.length == 4) {
							this.memberDataF = res.data[0].rights.slice(0, 4)
							this.memberDataS = res.data[0].rights.slice(4, 8)
							this.memberDataT = res.data[0].rights.slice(8, 12)
						} else if (this.memberData.rights.length == 9 || this.memberData.rights.length == 6 || this.memberData.rights.length == 3) {
							this.memberDataF = res.data[0].rights.slice(0, 3)
							this.memberDataS = res.data[0].rights.slice(3, 6)
							this.memberDataT = res.data[0].rights.slice(6, 9)
						} else if (this.memberData.rights.length == 7 || this.memberData.rights.length == 5) {
							this.memberDataF = res.data[0].rights.slice(0, 4)
							this.memberDataS = res.data[0].rights.slice(4, 8)
						} else if (this.memberData.rights.length == 11 || this.memberData.rights.length == 10) {
							this.memberDataF = res.data[0].rights.slice(0, 4)
							this.memberDataS = res.data[0].rights.slice(4, 8)
							this.memberDataT = res.data[0].rights.slice(8, 12)
						} else {
							this.memberDatAll = res.data[0].rights
						}

						// this.memberDataS = res.data[0].rights

						if (res.data.length != 0) {
							this.headerForm.background = "#242430"
							this.isMember = true
						} else {
							this.headerForm.background = "#ffffff"
							this.isMember = false
						}
					}
				}).catch(err => {
					this.headerForm.background = "#ffffff"
					this.isMember = false
					// console.log("err", err.message)
				}).finally(errs => {

					// console.log("errs", errs.message)
				})
			},


			// isUndated() {
			// 	if (this.isUndate) {
			// 		this.$router.go(0)
			// 		this.isUndate = false
			// 	}
			// }

			Swiper() {

			}

		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.$swiper
			}
		},
		mounted() {
			// console.log('window.location attr', window.location)
			// var swiper1 = new Swiper('.swiper-container', {
			// 	// el: ".swiper-pagination",
			// 	// runCallbacksOnInit: true,
			// 	// effect: "coverflow",
			// 	initialSlide: 1, // 默认显示下标，自行设置
			// 	centeredSlides: true, // 居中显示
			// 	// swiper动态加载
			// 	observer: true,
			// 	observeParents: true,
			// 	// 第一张和最后一张贴合边缘 
			// 	// centeredSlidesBounds: true,

			// 	shortSwipes: true,  //短切换
			// 	longSwipes: true,
			// 	slidesPerView: 1.4,
			// 	grabCursor: true,
			// 	spaceBetween: 25,
			// 	longSwipesRatio: 0.1,  //拖动比例
			// 	freeMode: true,
			// 	freeModeSticky: true,
			// 	freeModeMomentumBounce: true,
			// 	loop: false,
			// 	speed: 100,
			// })
			this.getlikeList();
			this.getCommodityMemberships()
			const index = this.getQueryVariable("index") || 0
			// console.log("this.$refs", this.$refs)

			const { activityId } = this.$route.params;
			window.Pay_now = this.Pay_now;
			const showPlatform = this.getQueryVariable("showPlatform");
			this.showPlatform = showPlatform;
			// this.getPosition();

		}
	};
</script>

<style lang="scss" scoped>
	body {
		font-family: PingFangSC-Regular, PingFang SC;
	}

	.text_navigation {
		height: 43px;
		background: #fff;
		margin: 0 20px;
		position: relative;
		/* // overflow: hidden; */
	}

	.navimg_list {
		height: 43px;
	}

	.nav_list {
		overflow-x: scroll;
		white-space: nowrap;
		user-select: none;
		padding-right: 42px;
		/* // border-radius: 3px; */
		overflow: hidden;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.nav_mylist {
		width: 100%;
		position: fixed;
		top: 0 !important;
		z-index: 99;
	}

	.nav_item-warp {
		// width: 108px;
		display: inline-block;
		vertical-align: middle;

		.nav_img {
			background-position: 50%;
			background-size: cover;
			display: block;
			margin: 0 auto;
			padding-top: 100%;
		}

		.nav_title {
			padding: 0 5px 9px 5px;

			font-size: 14px;
			box-sizing: border-box;
			overflow: hidden;
			text-align: center;

			.nav_title_text {
				position: relative;
				color: #333;

				// color: #ff5a5a;
				.nav_title_line {
					width: 100%;
					height: 2px;
					position: absolute;
					bottom: -7px;
					left: 0;
					background: #ff5a5a;
					border-radius: 2px;
				}
			}
		}

		.nav_title_img {
			height: 33px;
			line-height: 24px;
			padding: 0 5px 9px 5px;
		}
	}

	.nav_item-textWarp {
		width: 98px;

		.nav_title {
			padding: 12px 0;
		}
	}

	.nav_more {
		position: absolute;
		right: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 45px;
		height: 45px;
		border-radius: 6px;
		box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
		background: #fff;
		z-index: 1;
		cursor: pointer;
	}

	.all_nav {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		background: #fff;
		border-radius: 6px;
		overflow: hidden;

		.all_nav_text {
			min-height: 45px;
			line-height: 45px;
			text-align: center;
			font-weight: 700;
			color: #8d8b8b;
		}

		.all_navList {
			background: #fff;
			opacity: 0.8;
			max-height: 325px;
			padding-bottom: 10px;

			.nav_item-textWarp {
				width: 33%;
			}
		}
	}

	.navfix {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
	}

	.overlay_wrapper {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 80%;
		margin-left: -40%;
		margin-top: -50%;
		text-align: center;

		img {
			display: block;
		}
	}

	.app_body {
		background-size: 100% 100% !important;
		background-repeat: no-repeat !important;
		box-sizing: border-box;
		max-width: 750px;
		margin: 0 auto;
		user-select: none;
		position: relative;
		padding-bottom: 10px;
		min-height: 100vh;

		&::-webkit-scrollbar {
			display: none;
		}

		.rule {
			position: absolute;
			top: 20px;
			right: 0;
			z-index: 1;
			font-size: 12px;
			color: #eee;
			background: rgba(0, 0, 0, 0.2);
			border-radius: 13px 0 0 13px;
			height: 25px;
			line-height: 25px;
			padding: 0px 10px 0 10px;
			z-index: 99;
		}

		.rule_item {
			font-size: 14px;
			padding: 5px 0;
		}

		.ab_warp {
			position: relative;
			overflow: hidden;

			.default {
				display: flex;
				flex-direction: column;
				justify-content: center;
				background-color: #ebf8fd;
				text-align: center;
				color: #88c4dc;
			}

			.ad_temp_warp {
				text-align: center;
				padding: 5px;
				background: #fff;
				border: 1px solid #e5e5e5;
				margin: 0 10px 10px 0;
			}

			.adDataList {
				overflow: hidden;
			}

			.adDataItem {}

			.adDataItem_link {
				display: block;
				position: relative;

				.adDataItem_img {
					width: 100%;
					height: auto;
					display: block;
					// background: #eee;
					// min-height: 100px;
				}

				.adImgItem_title {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba(0, 0, 0, 0.4);
					color: #fff;
					font-size: 14px;
					height: 30px;
					line-height: 30px;
					text-align: center;
				}
			}
		}

		.product_warp {
			border: 1px dashed transparent;
			position: relative;

			.defule {
				overflow: hidden;
			}

			.ant-radio-wrapper {
				margin-bottom: 12px;
			}

			.product_list {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;

				&::-webkit-scrollbar {
					display: none;
				}
			}

			.product {
				box-sizing: border-box;

				a {
					display: block;
				}

				.product_imgwarp {
					// position: relative;
					// overflow: hidden;
					// text-align: center;
					// padding-top: 20px;
					// user-select: none;

					padding-top: 132px;
					position: relative;
					overflow: hidden;
					max-height: 500px;

					.product_img {
						background-size: cover;
						width: 100%;
						background-position: 50%;
						background-repeat: no-repeat;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;

						// width: 105px;
						// height: 121px;
						// background: #eee;
						// user-select: none;
						.product_label {
							position: absolute;
							background: #f44;
							color: #fff;
							font-size: 12px;
						}

						.product_label_1 {
							left: 0;
							top: 11px;
							padding: 0 8px 0 5px;
							height: 22px;
							line-height: 22px;
							border-radius: 0 11px 11px 0;
						}

						.product_label_2 {
							left: 10px;
							top: 10px;
							width: 22px;
							text-align: center;
							height: auto;
							border-radius: 2px;
							padding: 2px 0;
						}
					}
				}

				.product_namewarp {
					font-size: 14px;
					color: #201f1f;
					padding: 0 8px 8px 8px;

					.name {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						font-size: 14px;
						min-height: 36px;
						max-height: 38px;
					}
				}

				.price_warp {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-top: 6px;

					.price {
						color: #000;

						.price-tag {
							font-size: 12px;
						}

						.price-num {
							// font-size: 14px;
						}
					}

					.playnum {
						color: #bcbcbc;
						font-size: 12px;
					}
				}
			}
		}

		.addcommodity_list {
			background: #fff;
			padding: 15px;
			display: flex;
			flex-wrap: wrap;
			border: 1px solid #e5e5e5;
			border-radius: 3px;
			overflow: hidden;

			.productItem {
				border: 1px solid #ddd;
				padding: 10px;
				margin: 0 10px 10px 0;
				cursor: pointer;
				position: relative;
				z-index: 9;

				.productItem_img {
					display: block;
					width: 100%;
					height: 100%;
				}
			}
		}

		.addcommodity {
			width: 50px;
			height: 50px;
			cursor: pointer;
			margin: 0 10px 10px 0;
			display: block;
			border: 1px dashed #ddd;
			background-color: #fff;
			text-align: center;
			color: #38f;
			font-size: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0;
		}

		.title_warp {
			text-align: left;
			position: relative;

			.defule {
				padding: 10px !important;
				background-repeat: no-repeat !important;
				background-size: 100% 100% !important;

				div {
					margin: 0;
				}

				span {
					font-size: 12px;
					color: #8c8c8c;
					margin: 0;
				}
			}
		}

		.aggregate_warp {
			position: relative;

			p {
				margin: 0 !important;
			}

			img {
				display: block;
			}
		}
	}

	.activityTag {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		color: #f0364e;
		background: #ffdbe0;
		padding: 2px 5px;
		border-radius: 10px 0 10px 0;
		margin-right: 4px;
	}

	.btnBuy_style3 {
		border: 1px solid #ff4444;
		background-color: #ff4444;
		color: #fff !important;
		height: 24px;
		padding: 0px 8px;
		line-height: 24px;
	}

	.btnBuy_style4 {
		border: 1px solid #ff4444;
		background-color: #ff4444;
		color: #fff !important;
		height: 24px;
		padding: 0px 8px;
		line-height: 24px;
		border-radius: 12px;
	}

	.btnBuy_style5 {
		border: 1px solid #ff4444;
		color: #ff4444 !important;
		height: 24px;
		padding: 0px 8px;
		line-height: 24px;
	}

	.btnBuy_style6 {
		border: 1px solid #ff4444;
		color: #ff4444 !important;
		height: 24px;
		padding: 0px 8px;
		line-height: 24px;
		border-radius: 12px;
	}

	.goods_style3 {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 10px 8px 12px 8px !important;
		flex: 1;
	}

	/* 修改 */

	.memberbox {}

	.memberHead {
		.mbhi {
			width: auto;
			padding-top: 130px;

			.mbhimages {
				position: relative;
				/* left: 50%;
				transform: translateX(-50%); */
				width: 100%;
				height: 164px;

				img {
					position: absolute;
					width: 100%;
					/* height: auto; */
					height: 162px;
					z-index: -1;
				}

				.mbh_center {
					z-index: 2;
					padding: 17px 20px 0 20px;
					color: #FFFFFF;

					.mb_title {
						font-weight: blod;
						font-size: 18px;
					}

					.mb_centertitle {
						margin-top: 10px;
						font-size: 16px;
					}

					.mbh_btn {
						position: relative;
						width: auto;
						height: 100%;
						left: 50%;
						transform: translateX(-50%);
						bottom: -36px;
						/* line-height: 42px; */
						display: flex;
						/* justify-content: space-evenly; */
						align-items: center;

						img {
							position: absolute;
							width: 100%;
							height: auto;
						}

						.mbh_spaceBox {
							display: inline-block;

							width: 56%;
							text-align: center;
							/* margin-left: -8px; */
							word-break: keep-all;
							white-space: nowrap;

							.mbh_space {
								color: #fff;
								font-size: 14px;
								/* font-size: 1.4rem; */
								font-weight: blod;
							}

							.mbh_discount {
								/* font-size: 9px; */
								/* font-size: 1rem; */
								margin-left: 1px;
								transform: scale(0.92);
								color: #fff;
								display: inline-block;
								opacity: 0.8;
							}

							.mbh_discounts {
								text-decoration: line-through;
								margin-left: -3px;
							}
						}

						.mbh_Pay {
							font-size: 14px;
							display: inline-block;
							/* width: 45%; */
							font-weight: bold;
							text-align: center;
						}

					}


				}
			}



		}

	}

	.memberCenter {
		margin-top: 13px;
		padding: 0 15px;

		.mbc_title {
			width: 100%;
			text-align: center;

			img {
				width: 156px;
				height: auto;

			}
		}

		.mbc_Member {
			background: #44495E;
			border-radius: 5px;
			margin-top: 18px;
			padding: 15px 10px 30px 15px;

			.mbcm_title {
				display: flex;
				justify-content: space-between;

				p:first-child {
					font-weight: bold;
					color: #fff;
					font-size: 15px;
				}

				p:last-child {
					color: #FEF0CB;
					font-size: 13px;
				}
			}

			.mbcm_center {
				/* display: flex;
				justify-content: space-between;
				flex-wrap: wrap; */
				width: 100%;

				.mbcmc_bigBox {
					display: flex;
					justify-content: space-between;
					align-content: flex-start;
					/* flex-wrap: wrap; */
				}

				.mbcmc_bigBox2 {
					display: flex;
					justify-content: space-between;
					align-content: flex-start;
					flex-wrap: wrap;

				}

				.mbcmc_box {
					text-align: center;
					width: 60px;
					white-space: nowrap;

					/* width: 25%; */
					/* margin-right: 2rem; */
					margin-top: 30px;

					img {
						width: 46px;
						height: 46px;
						border-radius: 50%;
					}

					p {
						margin-top: 5px;
						font-size: 13px;
						color: #FEF0CB;
					}
				}

				.mbcmc_box2 {
					text-align: center;
					/* width: 25%; */
					width: 60px;
					white-space: nowrap;
					/* margin-right: 8%; */
					margin-top: 30px;

					img {
						width: 46px;
						height: 46px;
						border-radius: 50%;
					}

					p {
						/* min-width: 59px; */
						margin-top: 5px;
						font-size: 13px;
						color: #FEF0CB;
					}

					.mbcmc_bocc {
						width: 46px;
						height: 46px;
						border-radius: 50%;
					}
				}

				.mbcmc_box3 {
					/* width: 25%; */
					position: absolute;
					bottom: 0;
					left: 0;

					.mabcm_icon {
						text-align: center;
					}

					img {
						width: 46px;
						height: 46px;
						border-radius: 50%;
					}

					p {
						margin-top: 5px;
						font-size: 13px;
						color: #FEF0CB;
					}
				}

				.mbcmc_boxMain {
					width: 25%;
					position: relative;
					height: 71px;
					margin-top: 30px;
				}

				/* .mbcmc_boxMain:nth-child(2) .mbcmc_box3 {
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					width: 100%;
					text-align: center;
				}

				.mbcmc_boxMain:nth-child(3) .mbcmc_box3 {
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					width: 100%;
					text-align: center;
				} */


				.mbcmc_boxMain:nth-child(3) {
					text-align: center;
				}


				.mbcmc_box:nth-child(4n) {
					margin-right: 0;
				}

				.mbcmc_box2:nth-child(4n) {
					margin-right: 0;
				}
			}
		}

		.mbc_git {
			margin-top: 15px;
			padding: 15px 10px;
			background: #44495E;
			border-radius: 5px;

			.mbc_gitfTitle {
				font-weight: blod;
				font-size: 15px;
				color: #FFFFFF;
			}

			.mbcg_centerTitle {
				font-weight: 400;
				font-size: 15px;
				color: #FEF0CB;
				margin-top: 10px;
			}

			.mbcg_Card {
				display: flex;
				margin-top: 10px;
				overflow: hidden;
				display: box;
				overflow-x: scroll;

				.mbcg_Cardmain {

					position: relative;
					margin-right: 10px;
					width: 155px;
					z-index: 1;
					display: flex;
					height: 86px;

					img {
						width: 155px;
						height: 86px;
						position: absolute;
						z-index: -1;
					}

					.mbcg_cmRight {
						width: 104px;
						margin-top: 12px;
						margin-left: 9px;

						.cmRght_top {
							span {
								font-size: 15px;
							}

							font-size: 28px;
							font-weight: blod;
							margin-top: 3px;
						}

						.cmRght_top2 {
							font-size: 18px;
							font-weight: blod;
							margin-top: 12px;
						}


						.cmRght_footer {
							text-align: left;
							font-size: 10px;
							transform: scale(0.82);
							margin-left: -8px;
							letter-spacing: 1px;
						}
					}

					.mbcg_cmLeft {
						span {
							width: 12px;
							display: inline-block;
						}

						display:flex;
						justify-content: center;
						align-items: center;
						writing-mode:vertical-lr;
						letter-spacing: 3px;
						text-align:center;
						width: 40px;
						font-size: 12px;
						color: #F0364E;
					}
				}
			}
		}

		.mbc_price {
			margin-top: 15px;
			padding: 15px 10px;
			background: #44495E;
			border-radius: 5px;
			font-size: 15px;
			color: #fff;

			p {
				display: flex;
				justify-content: space-between;

				span {
					display: block;
				}
			}

			p:first-child {}

			p:last-child {
				margin-top: 10px;
			}
		}

		.mbc_footer {
			margin-top: 15px;
			padding: 15px 10px;
			background: #44495E;
			border-radius: 5px;
			color: #FFFFFF;

			.mbcf_title {
				font-size: 15px;
			}

			.mbcf_alipay {
				font-size: 13px;
				display: flex;
				justify-content: space-between;
				margin-top: 15px;

				p {
					img {
						width: 20px;
						height: 20px;
						margin-right: 5px;
						vertical-align: middle;

					}

					span {}
				}

				p:last-child {
					img {
						width: 16px;
						height: 16px;
						vertical-align: middle;

					}
				}
			}

			.mbcf_alipay2 {
				font-size: 13px;
				display: flex;
				justify-content: space-between;
				margin-top: 15px;

				p {
					img {
						width: 20px;
						height: 16px;
						margin-right: 5px;
						vertical-align: bottom;

					}

					span {}
				}

				p:last-child {
					img {
						width: 16px;
						height: 16px;
						vertical-align: middle;

					}
				}
			}

		}
	}






	.memberFooter {
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0;
		background: #44495E;
		width: 100%;
		z-index: 1;
	}

	.memberFooter .member_pay {
		width: 85%;
		text-align: center;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: bold;
		color: #242430;
		font-size: 18px;
		padding: 12px 0;
		margin: 10px 30px;
		background-image: linear-gradient(#ECBD83, #F8D9AD);
		border-radius: 26px;
	}


	/* 猜你喜欢 */
	.like_listTitle {
		text-align: center;
		margin-top: 23px;

		img {
			width: 117px;
			height: auto;
		}
	}

	.like_list {
		margin-top: 22px;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 70px;
		/* padding: 0 2.5px; */
		box-sizing: border-box;
		justify-content: space-between;
	}


	.like_item_warp {
		width: 50%;
		/* width: 174px; */
		box-sizing: border-box;
		/* margin: 0px 7px 7px 0; */
		margin-bottom: 7px;
	}

	.like_item_warp:nth-child(2n) .like_item {
		/* margin: 0px 0px 7px 0; */
		/* margin-left: 7px;
		margin-bottom: 7px; */
		margin-left: 7px;
	}

	.like_item {
		box-sizing: border-box;
		/* border-radius: 4px; */
		background-color: #fff;
		/* margin: 0 7px 5px; */
	}

	.like_item:nth-child(2n) {
		margin: 0px 0px 7px 0;
	}

	/* .like_item:nth-child(even) {
    margin-right: 0;
    margin-left: 0;
	} */

	.like_item-imgbox {
		height: 148px;
		position: relative;
	}

	.like_item-img {
		width: 105px;
		height: 121px;
		position: absolute;
		top: 20px;
		left: 50%;
		margin-left: -52.5px;
	}

	.like_item-info {
		padding: 10px 12px 12px 12px;
	}

	.like_item-name {
		min-height: 38px;
		font-size: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		font-family: PingFangSC-Semibold;
		color: #201F1F;
	}

	.like_item-people {
		padding-top: 10px;
		font-size: 12px;
		display: flex;
		justify-content: space-between;
		font-family: PingFangSC-Semibold;
	}

	.like_item-price {
		color: #F0374D;
		font-size: 16px;
		font-weight: bold;
		font-family: Arial-BoldMT, Arial;
		display: flex;
		align-items: baseline;
	}

	.rmb {
		color: #F0374D;
		font-size: 12px;
		font-weight: bold;
		font-family: Arial-BoldMT, Arial;
	}

	.lick_black {
		background-color: #000000;
		padding: 0 3px;
		color: #E09B6C;
		transform: scale(0.82);
		font-size: 10px;
		margin-left: 4px;
		/* margin-right: 2px; */
	}

	.rmb2 {
		transform: scale(0.7);
		color: #000000;
		font-size: 7px;
		font-weight: bold;
		font-family: Arial-BoldMT, Arial;
	}

	.black_rmb {
		/* transform: scale(0.9); */
		font-size: 11px;
		color: #000000;
	}

	.like_item-play {
		color: #bcbcbc;
		font-size: 10px;
		margin-top: 5px;
	}

	.mbcg_Card::-webkit-scrollbar {
		display: none;
	}

	/* // 火狐浏览器 隐藏滚动条 IE浏览器 隐藏滚动条 */
	.mbcg_Card {
		scrollbar-width: none;
		/* Firefox */
		-ms-overflow-style: none;
	}

	.activity_member {
		background-color: #242430;
	}

	.activity_member2 {
		background-color: #FFFFFF;
	}

	.app_body_box2 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		/* text-align: center; */
		/* align-content: center; */
		align-items: center;
		padding-top: 90px;

		img {
			width: 191px;
			height: 195px;
			/* border: 1px #999 dashed; */
			margin-bottom: 30px;
		}

		p {
			text-align: center;
			color: #7F7F7F;
			/* width: 106px; */
			font-size: 15px;
		}

		p:first-child {}
	}


	/* 上一张 */
	.memberHead .swiper-slide-prev .mbhi .mbhimages {
		height: 150px !important;
		/* transition: all 1s; */
		margin-top: 10px;
		/* margin-right: -20px !important; */
		/* margin-left: -20px !important; */
		margin-left: 4px !important;
	}

	.memberHead .swiper-slide-prev .mbhi .mbhimages .mbhimages_img {
		height: 150px !important;
		/* transition: all 1s; */


	}

	.memberHead .swiper-slide-prev .mbhi .mbhimages .mbh_center .mbh_btn {
		/* position: relative;
		width: auto;
		height: 100%;
		left: 50%;
		transform: translateX(-50%);
		bottom: -28px;
		display: flex;
		align-items: center; */
	}


	/* 下一张 */

	.memberHead .swiper-slide-next .mbhi .mbhimages {
		height: 150px !important;
		/* transition: all 1s; */
		margin-top: 10px;
	}

	.memberHead .swiper-slide-next .mbhi .mbhimages .mbhimages_img {
		height: 150px !important;
		/* transition: all 1s; */

	}

	.memberHead .swiper-slide-next .mbhi .mbhimages .mbh_center .mbh_btn {
		/* position: relative;
		width: auto;
		height: 100%;
		left: 50%;
		transform: translateX(-50%);
		bottom: -24px;
		display: flex;
		align-items: center; */
	}

	/* 中间的图片 */

	.memberHead .swiper-slide-active .mbhi .mbhimages {
		height: 162px !important;
		transition: all 0.5s;
	}

	.memberHead .swiper-slide-active .mbhi .mbhimages .mbhimages_img {
		height: 162px !important;
		/* transition: height 0.5s; */
	}

	.memberHead .swiper-slide {
		/* width: 80% !important; */
	}

	.swiper-container {
		overflow: hidden !important;
	}

	.swiper-container .swiper-wrapper .swiper-slide {
		/* width: 4.9rem; */
	}

	/* .swiper-container .swiper-wrapper .swiper-slide img{width: 100%; height: 3.20rem; border-radius: .20rem;} */
	.swiper-container .swiper-wrapper .swiper-slide-prev {
		/* margin-left: -15px; */
	}

	/* .swiper-container .swiper-wrapper .swiper-slide-prev img{ height: 2.84rem!important;} */
	.swiper-container .swiper-wrapper .swiper-slide-next {
		/* width: 0.1rem; */
		margin-left: -40px;

	}

	/* .swiper-container .swiper-wrapper .swiper-slide-next img{ height: 2.84rem!important;} */
	.swiper-container .swiper-wrapper .swiper-slide-active {
		width: 80% !important;
		position: relative;
		/* left: 50%; */
		transform: translateX(-18px);

	}


	.cube {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		backface-visibility: hidden;

		-webkit-perspective: 1000;
		-moz-perspective: 1000;
		-ms-perspective: 1000;
		perspective: 1000;
		/* Other transform properties here */
	}


	#cnzz_stat_icon_1279074062 {
		opacity: 0;
		position: relative;
		left: -100px;
	}
</style>