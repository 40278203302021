<template>
    <div class="app_body" :style="{
        background:headerForm.background,
    }">
        <p style="color:#fff;" v-if="showPlatform">当前环境A：{{checkPlatform()}}</p>
        <!--  -->
        <div class="rule" @click="lookRule" v-if="headerForm.rules">规则</div>
        <!--  -->
        <div class="memberHead" :style="note">
            <div class="mbhihead">
                <div class="mbhh_right">
                    <img :src="memberVip.headImgUrl" alt="">
                </div>
                <div class="mbhh_left">
                    <p>{{memberVip.nickName}}</p>
                    <p>会员总时长： <span>{{memberVip.expiredTime}}</span> 到期</p>
                </div>
            </div>
            <div class="mbhi">
                <div class="mbhimages" :style="note2">
                    <!-- <img v-if="member_img.serve_bg" :src="member_img.serve_bg" ref="element" alt=""> -->
                    <div class="mbh_center">
                        <div class="mb_title">
                            {{memberVip.name}}
                        </div>
                        <p class="mb_centertitle">
                            累计已省￥{{memberVip.totalSaveMoney}}元
                        </p>

                    </div>
                </div>
            </div>
        </div>

        <div class="memberCenter">
            <div class="mbc_title">
                <span class="mbc_titleactory"></span>
                <span>权益中心 </span>
                <!-- <span @click="equity(memberVip)">权益中心</span> -->
            </div>

            <div class="mbc_git">
                <van-tabs title-active-color="#FEF0CB" @click="van_click" background="#44495E" color="#44495E"
                    title-inactive-color="#FFFFFF" v-model="active" class="selectTab">
                    <van-tab title="第一周">
                        <div class="mbcg_centerTitle">{{memberVip.firstWeekTime}}</div>
                        <div class="mbcg_CardSorll">
                            <div class="mbcg_Card" :style="counHeight">
                                <template v-for="(item,index) in memberVip1">
                                    <div class="mbcg_Cardmain" :key="index" v-if="item.type == 1|| item.type == 2">
                                        <img v-if="week == active"
                                            :src="disabled== false?member_img.serve_card:member_img.serveFalse" alt="">
                                        <img v-else :src="member_img.serve_card" alt="">

                                        <div class="mbcg_cmRight ">
                                            <p :class="week== active && disabled == true?'mbcg_reduction  iswenziColor': 'mbcg_reduction'"
                                                v-if="item.type == 1">无门槛</p>
                                            <p :class="week== active && disabled == true?'mbcg_reduction iswenziColor': 'mbcg_reduction'"
                                                class="mbcg_reduction" v-if="item.type == 2">
                                                <!-- 满{{item.withAmount}}使用 -->
                                                满<span class="mbcg_withAmount">{{item.withAmount}}</span>使用
                                            </p>
                                            <p
                                                :class="week== active && disabled == true?'cmRght_top iswenziColor': 'cmRght_top'">
                                                <span>￥</span>{{item.usedAmount}}
                                            </p>
                                        </div>
                                        <div class="mbcg_cmCenter">
                                            <p
                                                :class="week== active && disabled == true?'mbcgc_title iswenziColor': 'mbcgc_title'">
                                                {{item.title}}</p>
                                            <p
                                                :class="week== active && disabled == true?'mbcgc_time iswenziColor': 'mbcgc_time'">
                                                仅限{{memberVip.firstWeekTime}}使用</p>
                                        </div>
                                        <div
                                            :class="week== active && disabled == true?'mbcg_cmLeft iswenziColor': 'mbcg_cmLeft'">
                                            <span>全品类</span>
                                        </div>
                                    </div>
                                    <div class="mbcg_Cardmain mbcg_CardMainChang" v-if="item.type == 3" :key="index">
                                        <img v-if="week==active"
                                            :src="disabled == false?member_img.serve_speed:member_img.serve_freecard"
                                            alt="">
                                        <img v-else :src="member_img.serve_speed" alt="">
                                        <div class="mbcg_cmRight servememcolor">
                                            <p :class="week== active && disabled == true?'iswenziColor': ''">
                                                {{item.title}}</p>
                                            <p :class="week== active && disabled == true?'iswenziColor': ''">
                                                {{item.titleX}}</p>
                                        </div>
                                        <div class="mbcg_cmCenter mbcg_Cardcenter">
                                            <p
                                                :class="week== active && disabled == true?'mbcgc_time iswenziColor': 'mbcgc_time'">
                                                仅限{{memberVip.firstWeekTime}}使用</p>
                                        </div>
                                        <div
                                            :class="week== active && disabled == true?'mbcg_cmLeft iswenziColor': 'mbcg_cmLeft'">
                                            <span>美妆</span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="memberG_btn">
                            <p>
                                <!-- <van-button type="primary" size="large"></van-button> -->
                                <van-button v-if="week==active" @click="getMember(0)" :disabled="disabled"
                                    type="primary" color="linear-gradient(to bottom,#D6A364,#F8D9AD)" block>
                                    <span class="getspan" v-if="active == week"> {{disabled ==
                                        false?"一键领取":"已领取"}}</span>
                                    <span class="getspan" v-else>{{week > active?"已过期":"待开放"}} </span>
                                </van-button>

                                <van-button v-else type="primary" :disabled="true"
                                    color="linear-gradient(to bottom,#D6A364,#F8D9AD)" block>
                                    <span class="getspan" v-if="active == week"> {{disabled ==
                                        false?"一键领取":"已领取"}}</span>
                                    <span class="getspan" v-else>{{week > active?"已过期":"待开放"}} </span>
                                </van-button>
                            </p>
                        </div>
                    </van-tab>

                    <van-tab title="第二周">
                        <div class="mbcg_centerTitle">{{memberVip.secondWeekTime}}</div>
                        <div class="mbcg_CardSorll">
                            <div class="mbcg_Card" :style="counHeight">
                                <template v-for="(item,index) in memberVip2">
                                    <div class="mbcg_Cardmain" :key="index" v-if="item.type == 1|| item.type == 2">
                                        <img v-if="week == active"
                                            :src="disabled== false?member_img.serve_card:member_img.serveFalse" alt="">
                                        <img v-else :src="member_img.serve_card" alt="">

                                        <div class="mbcg_cmRight ">
                                            <p :class="week== active && disabled == true?'mbcg_reduction  iswenziColor': 'mbcg_reduction'"
                                                v-if="item.type == 1">无门槛</p>
                                            <p :class="week== active && disabled == true?'mbcg_reduction iswenziColor': 'mbcg_reduction'"
                                                class="mbcg_reduction" v-if="item.type == 2">
                                                <!-- 满{{item.withAmount}}使用 -->
                                                满<span class="mbcg_withAmount">{{item.withAmount}}</span>使用
                                            </p>
                                            <p
                                                :class="week== active && disabled == true?'cmRght_top iswenziColor': 'cmRght_top'">
                                                <span>￥</span>{{item.usedAmount}}
                                            </p>
                                        </div>
                                        <div class="mbcg_cmCenter">
                                            <p
                                                :class="week== active && disabled == true?'mbcgc_title iswenziColor': 'mbcgc_title'">
                                                {{item.title}}</p>
                                            <p
                                                :class="week== active && disabled == true?'mbcgc_time iswenziColor': 'mbcgc_time'">
                                                仅限{{memberVip.secondWeekTime}}使用</p>
                                        </div>
                                        <div
                                            :class="week== active && disabled == true?'mbcg_cmLeft iswenziColor': 'mbcg_cmLeft'">
                                            <span>全品类</span>
                                        </div>
                                    </div>
                                    <div class="mbcg_Cardmain mbcg_CardMainChang" v-if="item.type == 3" :key="index">
                                        <img v-if="week==active"
                                            :src="disabled == false?member_img.serve_speed:member_img.serve_freecard"
                                            alt="">
                                        <img v-else :src="member_img.serve_speed" alt="">
                                        <div class="mbcg_cmRight servememcolor">
                                            <p :class="week== active && disabled == true?'iswenziColor': ''">
                                                {{item.title}}</p>
                                            <p :class="week== active && disabled == true?'iswenziColor': ''">
                                                {{item.titleX}}</p>
                                        </div>
                                        <div class="mbcg_cmCenter mbcg_Cardcenter">
                                            <p
                                                :class="week== active && disabled == true?'mbcgc_time iswenziColor': 'mbcgc_time'">
                                                仅限{{memberVip.secondWeekTime}}使用</p>
                                        </div>
                                        <div
                                            :class="week== active && disabled == true?'mbcg_cmLeft iswenziColor': 'mbcg_cmLeft'">
                                            <span>美妆</span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="memberG_btn">
                            <p>
                                <!-- <van-button type="primary" size="large"></van-button> -->
                                <van-button v-if="week==active" @click="getMember(1)" :disabled="disabled"
                                    type="primary" color="linear-gradient(to bottom,#D6A364,#F8D9AD)" block>
                                    <span class="getspan" v-if="active == week"> {{disabled ==
                                        false?"一键领取":"已领取"}}</span>
                                    <span class="getspan" v-else>{{week > active?"已过期":"待开放"}} </span>
                                </van-button>

                                <van-button v-else type="primary" :disabled="true"
                                    color="linear-gradient(to bottom,#D6A364,#F8D9AD)" block>
                                    <span class="getspan" v-if="active == week"> {{disabled ==
                                        false?"一键领取":"已领取"}}</span>
                                    <span class="getspan" v-else>{{week > active?"已过期":"待开放"}} </span>
                                </van-button>
                            </p>
                        </div>

                    </van-tab>
                    <van-tab title="第三周">
                        <div class="mbcg_centerTitle">{{memberVip.thirdWeekTime}}</div>
                        <div class="mbcg_CardSorll">
                            <div class="mbcg_Card" :style="counHeight">
                                <template v-for="(item,index) in memberVip3">
                                    <div class="mbcg_Cardmain" :key="index" v-if="item.type == 1|| item.type == 2">
                                        <img v-if="week == active"
                                            :src="disabled== false?member_img.serve_card:member_img.serveFalse" alt="">
                                        <img v-else :src="member_img.serve_card" alt="">

                                        <div class="mbcg_cmRight ">
                                            <p :class="week== active && disabled == true?'mbcg_reduction  iswenziColor': 'mbcg_reduction'"
                                                v-if="item.type == 1">无门槛</p>
                                            <p :class="week== active && disabled == true?'mbcg_reduction iswenziColor': 'mbcg_reduction'"
                                                class="mbcg_reduction" v-if="item.type == 2">
                                                <!-- 满{{item.withAmount}}使用 -->
                                                满<span class="mbcg_withAmount">{{item.withAmount}}</span>使用
                                            </p>
                                            <p
                                                :class="week== active && disabled == true?'cmRght_top iswenziColor': 'cmRght_top'">
                                                <span>￥</span>{{item.usedAmount}}
                                            </p>
                                        </div>
                                        <div class="mbcg_cmCenter">
                                            <p
                                                :class="week== active && disabled == true?'mbcgc_title iswenziColor': 'mbcgc_title'">
                                                {{item.title}}</p>
                                            <p
                                                :class="week== active && disabled == true?'mbcgc_time iswenziColor': 'mbcgc_time'">
                                                仅限{{memberVip.thirdWeekTime}}使用</p>
                                        </div>
                                        <div
                                            :class="week== active && disabled == true?'mbcg_cmLeft iswenziColor': 'mbcg_cmLeft'">
                                            <span>全品类</span>
                                        </div>
                                    </div>
                                    <div class="mbcg_Cardmain mbcg_CardMainChang" v-if="item.type == 3" :key="index">
                                        <img v-if="week==active"
                                            :src="disabled == false?member_img.serve_speed:member_img.serve_freecard"
                                            alt="">
                                        <img v-else :src="member_img.serve_speed" alt="">
                                        <div class="mbcg_cmRight servememcolor">
                                            <p :class="week== active && disabled == true?'iswenziColor': ''">
                                                {{item.title}}</p>
                                            <p :class="week== active && disabled == true?'iswenziColor': ''">
                                                {{item.titleX}}</p>
                                        </div>
                                        <div class="mbcg_cmCenter mbcg_Cardcenter">
                                            <p
                                                :class="week== active && disabled == true?'mbcgc_time iswenziColor': 'mbcgc_time'">
                                                仅限{{memberVip.thirdWeekTime}}使用</p>
                                        </div>
                                        <div
                                            :class="week== active && disabled == true?'mbcg_cmLeft iswenziColor': 'mbcg_cmLeft'">
                                            <span>美妆</span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="memberG_btn">
                            <p>
                                <!-- <van-button type="primary" size="large"></van-button> -->
                                <van-button v-if="week==active" @click="getMember(2)" :disabled="disabled"
                                    type="primary" color="linear-gradient(to bottom,#D6A364,#F8D9AD)" block>
                                    <span class="getspan" v-if="active == week"> {{disabled ==
                                        false?"一键领取":"已领取"}}</span>
                                    <span class="getspan" v-else>{{week > active?"已过期":"待开放"}} </span>
                                </van-button>

                                <van-button v-else type="primary" :disabled="true"
                                    color="linear-gradient(to bottom,#D6A364,#F8D9AD)" block>
                                    <span class="getspan" v-if=" active==week"> {{disabled ==
                                        false?"一键领取":"已领取"}}</span>
                                    <span class="getspan" v-else>{{week > active?"已过期":"待开放"}} </span>
                                </van-button>
                            </p>
                        </div>
                    </van-tab>
                    <van-tab title="第四周">
                        <div class="mbcg_centerTitle">{{memberVip.fourWeekTime}}</div>
                        <div class="mbcg_CardSorll">
                            <div class="mbcg_Card" :style="counHeight">
                                <template v-for="(item,index) in memberVip4">
                                    <div class="mbcg_Cardmain" :key="index" v-if="item.type == 1|| item.type == 2">
                                        <img v-if="week == active"
                                            :src="disabled== false?member_img.serve_card:member_img.serveFalse" alt="">
                                        <img v-else :src="member_img.serve_card" alt="">

                                        <div class="mbcg_cmRight ">
                                            <p :class="week== active && disabled == true?'mbcg_reduction  iswenziColor': 'mbcg_reduction'"
                                                v-if="item.type == 1">无门槛</p>
                                            <p :class="week== active && disabled == true?'mbcg_reduction iswenziColor': 'mbcg_reduction'"
                                                class="mbcg_reduction" v-if="item.type == 2">
                                                <!-- 满{{item.withAmount}}使用 -->
                                                满<span class="mbcg_withAmount">{{item.withAmount}}</span>使用
                                            </p>
                                            <p
                                                :class="week== active && disabled == true?'cmRght_top iswenziColor': 'cmRght_top'">
                                                <span>￥</span>{{item.usedAmount}}
                                            </p>
                                        </div>
                                        <div class="mbcg_cmCenter">
                                            <p
                                                :class="week== active && disabled == true?'mbcgc_title iswenziColor': 'mbcgc_title'">
                                                {{item.title}}</p>
                                            <p
                                                :class="week== active && disabled == true?'mbcgc_time iswenziColor': 'mbcgc_time'">
                                                仅限{{memberVip.fourWeekTime}}使用</p>
                                        </div>
                                        <div
                                            :class="week== active && disabled == true?'mbcg_cmLeft iswenziColor': 'mbcg_cmLeft'">
                                            <span>全品类</span>
                                        </div>
                                    </div>
                                    <div class="mbcg_Cardmain mbcg_CardMainChang" v-if="item.type == 3" :key="index">
                                        <img v-if="week==active"
                                            :src="disabled == false?member_img.serve_speed:member_img.serve_freecard"
                                            alt="">
                                        <img v-else :src="member_img.serve_speed" alt="">
                                        <div class="mbcg_cmRight servememcolor">
                                            <p :class="week== active && disabled == true?'iswenziColor': ''">
                                                {{item.title}}</p>
                                            <p :class="week== active && disabled == true?'iswenziColor': ''">
                                                {{item.titleX}}</p>
                                        </div>
                                        <div class="mbcg_cmCenter mbcg_Cardcenter">
                                            <p
                                                :class="week== active && disabled == true?'mbcgc_time iswenziColor': 'mbcgc_time'">
                                                仅限{{memberVip.fourWeekTime}}使用</p>
                                        </div>
                                        <div
                                            :class="week== active && disabled == true?'mbcg_cmLeft iswenziColor': 'mbcg_cmLeft'">
                                            <span>美妆</span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="memberG_btn">
                            <p>
                                <!-- <van-button type="primary" size="large"></van-button> -->
                                <van-button v-if="week==active" @click="getMember(3)" :disabled="disabled"
                                    type="primary" color="linear-gradient(to bottom,#D6A364,#F8D9AD)" block>
                                    <span class="getspan" v-if="active == week"> {{disabled ==
                                        false?"一键领取":"已领取"}}</span>
                                    <span class="getspan" v-else>{{week > active?"已过期":"待开放"}} </span>
                                </van-button>

                                <van-button v-else type="primary" :disabled="true"
                                    color="linear-gradient(to bottom,#D6A364,#F8D9AD)" block>
                                    <span class="getspan" v-if="active == week"> {{disabled ==
                                        false?"一键领取":"已领取"}}</span>
                                    <span class="getspan" v-else>{{week > active?"已过期":"待开放"}} </span>
                                </van-button>
                            </p>
                        </div>
                    </van-tab>
                    <!-- <van-tab title="第五周">
                        5
                    </van-tab>
                    <van-tab title="第六周">
                        6
                    </van-tab> -->
                </van-tabs>

                <!-- <div class="memberG_btn">
                    <p>
                        <van-button @click="getMember" :disabled="disabled" type="primary"
                            color="linear-gradient(to bottom,#D6A364,#F8D9AD)" block>
                            <span class="getspan"> {{disabled == false?"一键领取":"已领取"}}</span>
                        </van-button>
                    </p>
                </div> -->
            </div>
        </div>
        <div class="mbc_serveBox">

            <div class="mbc_serveBottom" v-for="(item,index) in memberVip.rights" :key="index">
                <img v-if="item.showType == 0" :src="item.centerImage" @click="equitImage(item,index)" alt="">
            </div>
            <!-- <div class="mbc_serveBottom">
                <img :src="member_img.serve_yx" alt="">
            </div>
            <div class="mbc_serveBottom">
                <img :src="member_img.serve_kf" alt="">
            </div> -->
        </div>


        <!-- 立即支付 -->
        <!-- <div class="memberFooter">
            <p class="member_pay" @click="Pay_now">￥19.9立即支付</p>
        </div> -->


        <!-- <ul v-if="textNavigation && showNav" class="nav_list navfix" :style="{
					paddingRight:0,
					'overflow-x':textNavigation.config.isSlide==1?'scroll':'hidden',
					background:textNavigation.config.style.background
					}" ref="navlist">
            <li ref="navitem" @click="goscrol(navItem.link,navIndex,$event)" class="nav_item-warp nav_item-textWarp" :style="{
							width:textNavigation.config.isSlide==0?`calc(100% / ${textNavigation.config.navList.length})`:'108px'
						}" v-for="(navItem,navIndex) in textNavigation.config.navList" :key="`navconfig_${navIndex}`">
                <div class="nav_title">
                    <span class="nav_title_text" :style="{color:navIndex==navgatorIndex?'#ff5a5a':textNavigation.config.style.color}">
                        {{navItem.title}}
                        <i v-show="navIndex==navgatorIndex" class="nav_title_line" />
                    </span>
                </div>
            </li>
        </ul>-->
        <!--  -->
        <van-dialog v-model="showRule">
            <ul style="padding: 10px;">
                <li class="rule_item" v-for="(i,j) in rulesList" :key="j+'a'">{{i}}</li>
            </ul>
        </van-dialog>
        <!--  -->
        <!-- <van-overlay :show="show" @click="show = false">
            <div class="overlay_wrapper" @click.stop>
                <img :src="hintImgUrl" alt style="width:100%;" />
                <svg @click.stop="onClose" style="margin-top: 20px;" t="1602309924364" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2440" width="32" height="32">
                    <path d="M512 1016C233.6 1016 8 790.4 8 512S233.6 8 512 8 1016 233.6 1016 512 790.4 1016 512 1016z m0-960C260.8 56 56 260.8 56 512S260.8 968 512 968 968 763.2 968 512 763.2 56 512 56z" fill="#ffffff" p-id="2441" />
                    <path d="M548.8 512l195.2-195.2c9.6-9.6 9.6-25.6 0-36.8l-1.6-1.6c-9.6-9.6-25.6-9.6-35.2 0L512 475.2 316.8 280c-9.6-9.6-25.6-9.6-35.2 0l-1.6 1.6c-9.6 9.6-9.6 25.6 0 36.8L475.2 512 280 707.2c-9.6 9.6-9.6 25.6 0 36.8l1.6 1.6c9.6 9.6 25.6 9.6 35.2 0L512 548.8l195.2 195.2c9.6 9.6 25.6 9.6 35.2 0l1.6-1.6c9.6-9.6 9.6-25.6 0-36.8L548.8 512z" fill="#ffffff" p-id="2442" />
                </svg>
            </div>
        </van-overlay>-->
        <!--  -->
        <div style="position: fixed;right: 10px;bottom: 100px;opacity: 0.7;" @click="topPage" v-show="gotop">
            <img src="../../assets/top.png" style="width: 40px;height: 40px;" alt />
        </div>
    </div>
</template>
<script>

    // getMyCommodityMemberships
    import { getActivityInfo, onekeyCollection, getTakeStatusByBadId, getHotRecommendations, getMyCommodityMemberships } from "@/api/activity";
    // import { Swiper, SwiperSlide } from "vue-awesome-swiper";

    import "swiper/swiper-bundle.css";
    let recordTime = null;
    export default {
        inject: ["reload"],
        components: {
            // Swiper,
            // SwiperSlide,
        },
        data() {
            return {
                show: false,
                swiperOptions: {
                    autoplay: {
                        delay: 2000
                    },
                    speed: 250,
                    pagination: {
                        el: ".swiper-pagination"
                    },
                },
                isUndate: true,
                appBodyComponents: [],
                likeList: [],
                headerForm: {
                    name: "",
                    description: "",
                    background: "#242430",
                    rules: "",
                    shareLogo: ""
                },
                rulesList: [],
                showRule: false,
                showPlatform: false,
                takeStatus: 0,
                hintImgUrl: "",
                pindex: null,
                cindex: null,
                effectImgUrl: null,
                gotop: false,
                my_gotop: false,
                listBoxState: true,//点击导航栏时，暂时停止监听页面滚动
                navgatorIndex: 0,
                textNavigationId: null,
                textNavigation: null,
                showNav: false,
                toplist: [800, 4260, 7929],
                countDownInfo: null,
                countDownText: "00:00:00",
                commodityClickHintText: null,
                list1: [],
                member_img: {
                    headBg: require("../../assets/image/headBg@2x.png"),
                    Alipay: require("../../assets/image/Alipay@2x.png"),
                    allowance: require("../../assets/image/allowance@2x.png"),
                    carefree: require("../../assets/image/carefree@2x.png"),
                    discount: require("../../assets/image/discount@2x.png"),
                    discounts: require("../../assets/image/discounts@2x.png"),
                    ellipse: require("../../assets/image/ellipse@2x.png"),
                    equitTitle: require("../../assets/image/equitTitle@2x.png"),
                    member_check: require("../../assets/image/member_check@2x.png"),
                    menberBtn: require("../../assets/image/menberBtn@2x.png"),
                    payCard: require("../../assets/image/payCard@2x.png"),
                    Pinkage: require("../../assets/image/Pinkage@2x.png"),
                    query: require("../../assets/image/query@2x.png"),
                    service: require("../../assets/image/service@2x.png"),
                    shipments: require("../../assets/image/shipments@2x.png"),
                    wx_chat: require("../../assets/image/wx_chat@2x.png"),
                    likeTitle: require("../../assets/image/likeTitle@2x.png"),
                    memberCard: require("../../assets/image/memberCard@2x.png"),

                    // serve
                    serve_bg: require("../../assets/image/serve/serve_bg@2x.png"),
                    serve_by: require("../../assets/image/serve/serve_by@2x.png"),
                    serve_card: require("../../assets/image/serve/serve_card@2x.png"),
                    serve_freecard: require("../../assets/image/serve/serve_freecard@2x.png"),
                    serve_kf: require("../../assets/image/serve/serve_kf@2x.png"),
                    serve_yx: require("../../assets/image/serve/serve_yx@2x.png"),
                    serve_speed: require("../../assets/image/serve/serve_speed.png"),
                    serveFalse: require("../../assets/image/serve/serveFalse.png"),

                },
                note: {
                    backgroundImage: "url(" + require("../../assets/image/serve/seve_bg2.png") + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100%",
                    // marginTop: "5px",
                },
                note2: {
                    backgroundImage: "url(" + require("../../assets/image/serve/serve_bg@2x.png") + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100%",
                    // marginTop: "5px",
                },
                height: {
                    height: this.height,

                },
                memberVip: "",
                memberVip1: "",
                memberVip2: "",
                memberVip3: "",
                memberVip4: "",
                active: 0,
                disabled: false,
                memberUserId: "",
                week: "",
                userId: "",
                rightcoupons: "",
                counHeight: {}
            };
        },
        watch: {
            show(v) {
                if (!v) {
                    const { pindex, cindex, effectImgUrl } = this;
                    if (pindex !== null && cindex !== null) {
                        this.appBodyComponents[pindex].config.imgList[cindex].url = effectImgUrl;
                    }
                }
            },
            showNav(v) {
                if (!v) {
                    this.navgatorIndex = 0;
                }
            }
        },
        methods: {
            /* eslint-disable */
            topPage() {
                let top = document.documentElement.scrollTop || document.body.scrollTop;
                const timeTop = setInterval(() => {
                    document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
                    if (top <= 0) {
                        clearInterval(timeTop);
                    }
                }, 10);
            },
            // 根据礼包id以及用户id获取领取状态
            getTakeStatusByBadId(params) {
                return getTakeStatusByBadId(params).then(res => {
                    if (res.code === "200") {
                        return res.data;
                    } else {
                        this.$toast(res.message);
                    };
                }).catch(err => {
                    this.$toast(err.message);
                });
            },
            // 查看规则
            lookRule() {
                this.showRule = true;
            },
            onClose() {
                this.show = false;
            },
            equity(memberVip) {
                this.$router.push({
                    name: "equity",
                    params: {
                        userId: this.userId
                    }
                },
                )
            },
            // 点击广告图片

            // 礼包领取
            handleOnekeyCollection(params) {
                onekeyCollection(params).then(res => {
                    if (res.code === "200") {
                        this.effectImgUrl = res.data.effectImgUrl;
                        this.hintImgUrl = res.data.hintImgUrl;
                        this.takeStatus = res.data.takeStatus;
                        this.show = true;
                    } else {
                        this.$toast(res.message);
                    }
                }).catch(err => {
                    this.$toast(err.message);
                })
            },
            getMember(i) {
                if (i == this.week) {
                    onekeyCollection({ userId: this.userId }).then(res => {
                        if (res.code === "200") {
                            this.$toast("领取成功");
                            this.disabled = true
                        } else {
                            this.$toast(res.message);
                        }
                    }).catch(err => {
                        this.$toast(err.message);
                    })
                } else {

                    this.$toast("暂不开放哦~");
                }
            },
            //权益列表
            equitImage(i, index) {
                if (i.name.indexOf("客服") != -1) {
                    this.wechat()
                } else {
                    this.$router.push({
                        name: "equity",
                        params: {
                            index: index,
                            // slideIndex: 0,
                            rightList: this.memberVip.rights,
                        }
                    })
                }
            },
            // 传值微信号
            wechat(item) {
                const platform = this.checkPlatform();
                const eventTypeList = ["h5", "commodity", "coupon"];
                // const userId = this.getQueryVariable("userId");
                switch (platform) {
                    case "WeChatApplets":
                        wx.miniProgram.navigateTo({
                            url: "/pages/login_page/index"
                        })
                        break;
                    case "browser":
                        console.log("aaaaaa")
                        // window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.weijian.app";
                        break;
                    case "Android":
                        try {
                            android.postMessage(JSON.stringify({
                                eventType: "wechatId", params: {
                                    customerService: this.memberVip.customerService
                                }
                            }))
                        } catch (error) {
                            window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.weijian.app";
                        }
                        break;
                    case "Ios":
                        try {
                            window.webkit.messageHandlers.call.postMessage(JSON.stringify({
                                eventType: "wechatId", params: {
                                    customerService: this.memberVip.customerService
                                }
                            }));
                        } catch (error) {
                            window.location.href = `https://ios.weijianapp.com/app/`
                        }
                        break;
                    default:
                        break;
                }
            },
            getWechatMessage() {
                const platform = this.checkPlatform();
                const eventTypeList = ["h5", "commodity", "coupon"];
                // const userId = this.getQueryVariable("userId");
                switch (platform) {
                    case "WeChatApplets":
                        wx.miniProgram.navigateTo({
                            url: "/pages/login_page/index"
                        })
                        break;
                    case "browser":
                        // window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.weijian.app";
                        break;
                    case "Android":
                        try {
                            android.postMessage(JSON.stringify({
                                eventType: "getWechatMessage", params: {
                                    customerService: this.memberVip.customerService

                                }
                            }))
                        } catch (error) {
                            window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.weijian.app";
                        }
                        break;
                    case "Ios":
                        try {
                            window.webkit.messageHandlers.call.postMessage(JSON.stringify({
                                eventType: "getWechatMessage", params: {
                                    customerService: this.memberVip.customerService
                                }
                            }));
                        } catch (error) {
                            window.location.href = `https://ios.weijianapp.com/app/`
                        }
                        break;
                    default:
                        break;
                }
            },
            //猜你喜欢商品详情
            // onLookInfo(singleId) {
            //     window.open(`https://www.weijianapp.com/commodity/?singleId=${singleId}`);
            // },
            // 点击商品详情
            getShareInfo() {
                const { description, shareLogo, name } = this.headerForm;
                const platform = this.checkPlatform();
                /* eslint-disable */
                switch (platform) {
                    case "WeChat":
                        wx.miniProgram.postMessage(JSON.stringify({
                            eventType: "share", params: {
                                description: description,
                            }
                        }))
                        // wx.miniProgram.postMessage({ data: 'foo' })
                        break;
                    case "browser":
                        window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.weijian.app";
                        break;
                    case "Android":
                        android.postMessage(JSON.stringify({
                            eventType: "share", params: {
                                description: description,
                                shareLogo: shareLogo,
                                name: name
                            }
                        }))
                        break;
                    case "Ios":
                        window.webkit.messageHandlers.call.postMessage(JSON.stringify({
                            eventType: "share", params: {
                                description: description,
                                shareLogo: shareLogo,
                                name: name
                            }
                        }));
                        return JSON.stringify({
                            eventType: "share", params: {
                                description: description,
                                shareLogo: shareLogo,
                                name: name
                            }
                        })
                        break;
                    default:
                        break;
                }
                /* eslint-disable */
            },

            // 猜你喜欢
            getlikeList() {
                getHotRecommendations().then(async res => {
                    if (res.code === "200") {
                        this.likeList = res.data
                        console.log("this.likeList", this.likeList)
                    }
                }).catch(err => {
                    // console.log("err", err.message)
                }).finally(errs => {
                    // console.log("errs", errs.message)
                })
            },
            // 获取详情
            getActivityInfo(activityId) {
                const userId = this.getQueryVariable("userId");
                const _this = this;
                getActivityInfo({ activityId }).then(async res => {
                    console.log(res)
                    // eslint-disable-next-line no-empty
                    if (res.code === "200") {
                        const { background, data, description, name, rules, shareLogo } = res.data;
                        let newdata = JSON.parse(data);
                        let pindex = null;
                        let cindex = null;
                        let obj = null;
                        newdata.forEach((i, index) => {
                            if (i.template === "pictureAd") {
                                i.config.imgList && i.config.imgList.forEach((j, jindex) => {
                                    if (j.linkType === 3 && j.link && userId) {
                                        pindex = index;
                                        cindex = jindex;
                                        obj = { bagId: j.link, userId };
                                    }
                                });
                            }
                            // 
                            if (i.template === "textNavigation") {
                                _this.textNavigation = i;
                                _this.textNavigationId = i.id;
                            }
                            if (i.template === "countDown") {
                                _this.countDownInfo = i.config;
                            }
                        });
                        if (this.countDownInfo) {
                            this.startCountDown(this.countDownInfo.time);
                        }
                        if (obj !== null) {
                            let { takeStatus, effectImgUrl, hintImgUrl } = await this.getTakeStatusByBadId(obj);
                            this.takeStatus = takeStatus;
                            this.hintImgUrl = hintImgUrl;
                            if (pindex !== null && cindex !== null && takeStatus === 1) {
                                newdata[pindex].config.imgList[cindex].url = effectImgUrl;
                            }
                            // console.log(newdata, obj, pindex, cindex, effectImgUrl);
                        }
                        this.appBodyComponents = newdata;

                        this.headerForm.name = name;
                        this.headerForm.description = description;
                        this.headerForm.background = background;
                        this.headerForm.rules = rules;
                        this.headerForm.shareLogo = shareLogo;
                        document.title = name || "活动详情";
                        this.rulesList = rules && rules.split(/[\n,]/g);

                        // eslint-disable-next-line no-empty
                        // setTimeout(this.aaa(), 3000)
                    } else {
                        console.log(res.message)

                    }
                }).catch(err => {
                    console.log(res.message)

                }).finally(() => {

                });

            },

            handleCountDown(end, cb) {
                let nowtime = new Date(); //获取当前时间
                let endtime = new Date(end);//定义结束时间
                let lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
                    leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
                    lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
                    leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
                    lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
                if (lefth < 0 || leftm < 0 || lefts < 0) {
                    clearInterval(recordTime);
                    cb && cb();
                    return "00:00:00";
                }
                const prefixZero = (num, n) => {
                    return (Array(n).join(0) + num).slice(-n);
                };
                // console.log(`${leftd}天${lefth}时${leftm}分${lefts}秒`);
                return (
                    (leftd > 0 ? leftd * 24 + lefth : prefixZero(lefth, 2)) +
                    ":" +
                    prefixZero(leftm, 2) +
                    ":" +
                    prefixZero(lefts, 2)
                );
            },
            startCountDown(endtime) {
                clearInterval(recordTime);
                if (endtime) {
                    endtime = endtime.replace(/-/g, "/");
                    recordTime = setInterval(() => {
                        this.countDownText = this.handleCountDown(endtime, () => {
                            console.log("时间到");
                            this.appBodyComponents.forEach(item => {
                                if (item.template == "commodity") {
                                    item.config.buyButText = this.countDownInfo.btnText;
                                    this.commodityClickHintText = this.countDownInfo.hintText;
                                }
                            });
                        })
                    }, 1000)
                }
            },
            // 判断平台
            checkPlatform() {
                /* eslint-disable */
                function ready() {
                    return window.__wxjs_environment === 'miniprogram'
                }
                var a = navigator.userAgent.toLowerCase(),
                    isWeChat = a.match(/MicroMessenger/i) != null || a.match(/NetType/i) != null || a.match(/Language/i) != null;
                if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
                    document.addEventListener('WeixinJSBridgeReady', ready, false)
                }

                if (window.BoutiquePriceDetail) {
                    return "Android";
                } else if (ready()) {
                    return "WeChatApplets";
                } else if (window.webkit || /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                    return "Ios";
                } else if (/(Android)/i.test(navigator.userAgent)) {
                    return "Android";
                } else {
                    return "browser";
                }

                /* eslint-disable */
            },
            getQueryVariable(variable) {
                var query = window.location.search.substring(1);
                var vars = query.split("&");
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split("=");
                    if (pair[0] == variable) {
                        return pair[1];
                    }
                }
                return false;
            },
            getTimeServe(time) {
                // var time = "-10-10"
                var h1 = time.split("-")
                // console.log("h1", h1)
                // console.log("拼接", h1[0] + "." + h1[1] + '-' + h1[2] + '.' + h1[3])
                var time2 = ""
                // console.log("h1", h1)
                // console.log("h3", h1[0] + "." + h1[1] + '-' + h1[2] + '.' + h1[3])
                time2 = h1[0] + "." + h1[1] + '-' + h1[2] + '.' + h1[3]
                h1[0].charAt(0) == 0 ? h1[0] = h1[0].substr(1, 1) : h1[0]
                h1[1].charAt(0) == 0 ? h1[1] = h1[1].substr(1, 1) : h1[1]
                h1[2].charAt(0) == 0 ? h1[2] = h1[2].substr(1, 1) : h1[2]
                h1[3].charAt(0) == 0 ? h1[3] = h1[3].substr(1, 1) : h1[3]
                if (h1.length > 3) {
                    time2 = h1[0] + "." + h1[1] + '-' + h1[2] + '.' + h1[3]
                } else {
                    time2 = time
                }
                return time2
            },

            formatDate(v) {
                return formatDateCommon(v, "MM.dd");
            },

            formatDateCommon(v, format) {
                if (!v) {
                    return "";
                }
                var dateV = new Date(v);
                var year = dateV.getFullYear();
                var month = dateV.getMonth() + 1;
                month = month < 10 ? '0' + month : month;
                var date = dateV.getDate();
                date = date < 10 ? ("0" + date) : date;
                var hour = dateV.getHours();
                hour = hour < 10 ? ("0" + hour) : hour;
                var minute = dateV.getMinutes();
                minute = minute < 10 ? ("0" + minute) : minute;
                var second = dateV.getSeconds();
                second = second < 10 ? ("0" + second) : second;
                var str1 = year + "-" + month + "-" + date
                var str2 = hour + ":" + minute + ":" + second;
                var strmbcg_reduction
                if ("M.d" == format) {
                    str = str1;
                } else {
                    str = str1 + " " + str2
                }
                return str;
            },
            getMyCommodityMemberships() {
                let params = {
                    userId: this.userId
                }
                getMyCommodityMemberships(params).then(async res => {
                    if (res.code === "200") {
                        res.data.expiredTime = res.data.expiredTime.slice(0, 10)

                        // this.getTimeServe()
                        // console.log(" res.data.firstWeek", res.data.firstWeek)
                        // console.log("this.getTime()", this.getTime())
                        this.disabled = res.data.take
                        this.memberVip = res.data
                        this.memberVip1 = res.data.coupons
                        this.memberVip2 = res.data.coupons
                        this.memberVip3 = res.data.coupons
                        this.memberVip4 = res.data.coupons

                        this.active = res.data.week - 1
                        this.take = res.data.take
                        this.week = res.data.week - 1

                        res.data.firstWeekTime = this.getTimeServe(res.data.firstWeekTime)
                        res.data.secondWeekTime = this.getTimeServe(res.data.secondWeekTime)
                        res.data.thirdWeekTime = this.getTimeServe(res.data.thirdWeekTime)
                        res.data.fourWeekTime = this.getTimeServe(res.data.fourWeekTime)

                        let rightCounes = res.data.coupons
                        console.log("rightCounes上", rightCounes)

                        for (var i = 0; i < res.data.onlineCoupon; i++) {
                            let right = {
                                type: 3,
                                title: "免费",
                                titleX: "鉴别券"
                            }
                            rightCounes.push(right)
                        }

                        console.log("rightCounes中", rightCounes)

                        for (var i = 0; i < res.data.fastCoupon; i++) {
                            let right = {
                                type: 3,
                                title: "极速",
                                titleX: "鉴别券"
                            }
                            // rightCounes.push(right)
                            let v = []
                            v.push(right)
                            res.data.firstWeek == 1 ? this.memberVip1 = this.memberVip1.concat(v) : this.memberVip1 = this.memberVip1
                            res.data.secondWeek == 1 ? this.memberVip2 = this.memberVip2.concat(v) : this.memberVip2 = this.memberVip2
                            res.data.thirdWeek == 1 ? this.memberVip3 = this.memberVip3.concat(v) : this.memberVip3 = this.memberVip3
                            res.data.fourWeek == 1 ? this.memberVip4 = this.memberVip4.concat(v) : this.memberVip4 = this.memberVip4
                        }

                        let withData = [
                            this.memberVip1.length,
                            this.memberVip2.length,
                            this.memberVip3.length,
                            this.memberVip4.length,
                        ]
                        this.counHeight = {
                            minWidth: (withData[this.week] / 2) * 223 + 130 + "px"
                        }
                        this.getWechatMessage()

                    }
                }).catch(err => {
                    // console.log("err", err.message)
                }).finally(errs => {
                    // console.log("errs", errs.message)
                })
            },
            getHeight() {
                let dom = this.$refs.element;
            },
            van_click(event) {
                console.log("e", event)
                if (event == 0) {
                    this.counHeight = {
                        minWidth: (this.memberVip1.length / 2) * 223 + 130 + "px"
                    }
                } else if (event == 1) {
                    this.counHeight = {
                        minWidth: (this.memberVip2.length / 2) * 223 + 130 + "px"
                    }
                } else if (event == 2) {
                    this.counHeight = {
                        minWidth: (this.memberVip3.length / 2) * 223 + 130 + "px"
                    }
                } else if (event == 3) {
                    this.counHeight = {
                        minWidth: (this.memberVip4.length / 2) * 223 + 130 + "px"
                    }
                }
                this.active = event
            }

        },
        created() {

        },
        mounted() {
            // this.memberUserId = this.$route.params;
            window.equitImage = this.equitImage;
            // const showPlatform = this.getQueryVariable("showPlatform");
            this.userId = this.getQueryVariable("userId") || "871713431189717039"

            // this.showPlatform = showPlatform;

            this.getMyCommodityMemberships();

        }
    };
</script>

<style lang="scss" scoped>
    body {
        font-family: PingFangSC-Regular, PingFang SC;
    }

    /* 修改 */
    .memberHead {

        .mbhihead {
            padding: 0 15px;
            padding-top: 55px;
            display: flex;
            align-content: space-around;
            align-items: center;

            .mbhh_right {
                img {
                    width: 50px;
                    height: 50px;
                    border: 2px solid #fff;
                    border-radius: 50%;
                }
            }

            .mbhh_left {
                margin-left: 10px;
                font-weight: 600;
                color: #fff;
                font-size: 14px;

                p {}

                p:first-child {}

                p:last-child {
                    margin-top: 7px;
                    font-size: 10px;
                    color: #AAAAAA;

                    span {
                        color: #D7A567;
                    }
                }
            }
        }

        .mbhi {
            width: 100%;
            padding-top: 3vh;
            /* height: 161px; */
            /* min-height: 161px; */
            /* height: 24vh; */

            .mbhimages {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;

                /* height: 152px; */
                img {
                    position: absolute;
                    width: 100%;
                    /* height: 152px; */
                    z-index: -1;
                }

                .mbh_center {
                    z-index: 2;
                    padding: 28px 20px 0 26px;
                    color: #FFFFFF;
                    height: 130px;

                    .mb_title {
                        font-weight: blod;
                        font-size: 18px;
                    }

                    .mb_centertitle {
                        margin-top: 20px;
                        font-size: 16px;
                    }

                    .mbh_btn {
                        position: relative;
                        width: 88%;
                        height: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 18px;
                        line-height: 42px;

                        img {
                            position: absolute;
                            width: 100%;
                            height: auto;
                        }

                        .mbh_spaceBox {
                            display: inline-block;

                            width: 55%;
                            text-align: center;

                            .mbh_space {
                                color: #fff;
                                font-size: 15px;
                                font-weight: blod;
                            }

                            .mbh_discount {
                                font-size: 11px;
                                margin-left: 5px;
                                transform: scale(0.92);
                                color: #fff;

                                opacity: 0.8;
                            }

                            .mbh_discounts {
                                text-decoration: line-through;
                                margin-left: -3px;
                            }
                        }

                        .mbh_Pay {
                            font-size: 15px;
                            display: inline-block;
                            width: 45%;
                            font-weight: bold;
                            text-align: center;
                        }

                    }


                }
            }



        }

    }


    .memberCenter {
        padding: 0 15px;

        .mbc_title {
            .mbc_titleactory {
                display: inline-block;
                width: 5px;
                height: 18px;
                background: linear-gradient(180deg, #D6A364 0%, #F8D9AD 100%);
                margin-right: 5px;
                vertical-align: bottom;

            }

            width: 100%;
            font-size: 15px;
            color: #fff;
            font-weight: blod;

            img {
                width: 156px;
                height: auto;

            }
        }

        .mbc_Member {
            background: #44495E;
            border-radius: 5px;
            margin-top: 18px;

            padding: 15px 10px 30px 15px;

            .mbcm_title {
                display: flex;
                justify-content: space-between;

                p:first-child {
                    font-weight: bold;
                    color: #fff;
                    font-size: 15px;
                }

                p:last-child {
                    color: #FEF0CB;
                    font-size: 13px;
                }
            }

            .mbcm_center {

                .mbcmc_bigBox {
                    display: flex;
                    justify-content: space-between;
                    align-content: flex-start;
                    flex-wrap: wrap;
                }

                .mbcmc_box {
                    text-align: center;
                    margin-top: 30px;

                    img {
                        width: 46px;
                        height: 46px;
                    }

                    p {
                        margin-top: 5px;
                        font-size: 13px;
                        color: #FEF0CB;
                    }
                }
            }
        }

        .mbc_git {
            margin-top: 15px;
            padding: 15px 10px;
            background: #44495E;
            border-radius: 5px;
            overflow: hidden;
            padding-top: 10px;

            .mbc_gitfTitle {
                font-weight: blod;
                font-size: 15px;
                color: #FFFFFF;
            }

            .mbcg_centerTitle {
                font-weight: 400;
                font-size: 15px;
                color: #FEF0CB;
                margin-top: 6px;
            }

            .memberG_btn {
                margin-top: 20px;
                width: 100%;

                p {
                    border-radius: 3px;
                    font-size: 15px;
                    color: #242430;
                    font-weight: 600;
                    /* padding: 10px; */
                    background: linear-gradient(180deg, #D6A364 0%, #F8D9AD 100%);
                    text-align: center;

                    span {
                        font-size: 15px;
                        font-weight: 600;
                        color: #242430;
                    }
                }
            }

            .mbcg_CardSorll::-webkit-scrollbar {
                display: none;
            }

            /* // 火狐浏览器 隐藏滚动条 IE浏览器 隐藏滚动条 */
            .mbcg_CardSorll {
                scrollbar-width: none;
                /* Firefox */
                -ms-overflow-style: none;
            }

            .mbcg_CardColor {
                color: #493E2F !important;
                opacity: 0.5;
            }

            /* 一键领取按钮颜色 */
            .mbcg_btnColor {
                background: linear-gradient(180deg, #D6A364 0%, #F8D9AD 100%);
            }

            .ismbcg_btnColor {
                background: #cccccc;
            }

            .iswenziColor {
                color: #7F7F7F !important;
            }

            .mbcg_CardSorll {


                display: flex;
                flex-wrap: nowrap;
                overflow: hidden;
                flex-direction: column;
                overflow-x: scroll;

                .mbcg_Card {
                    display: flex;
                    /* margin-top: 7px; */
                    /* overflow-x: scroll; */
                    min-width: 1200px;
                    /* overflow-y: overlay; */
                    flex-wrap: wrap;
                    overflow-x: scroll;
                    overflow: hidden;


                    .mbcg_CardMainChang {
                        display: flex;
                        align-items: center;
                    }

                    .mbcg_Cardcenter {
                        text-align: center;
                    }

                    .mbcg_Cardmain {
                        position: relative;
                        margin-right: 10px;
                        width: 212px;
                        z-index: 1;
                        display: flex;
                        height: 63px;
                        margin-top: 10px;
                        max-height: 130px;

                        img {
                            width: 212px;
                            height: 63px;
                            position: absolute;
                            z-index: -1;
                        }

                        .mbcg_cmRight {
                            width: 104px;
                            text-align: center;
                            font-weight: 500;

                            .mbcg_reduction {
                                color: #F0364E;
                                font-size: 10px;
                                transform: scale(0.83);
                                margin-top: 5px;
                                vertical-align: -2px;
                                /* dispaly: flex;
                                align-items: baseline; */
                            }

                            .mbcg_reduction2 {
                                text-align: right !important;
                            }

                            .cmRght_top {
                                span {
                                    font-size: 10px;
                                    margin-right: -3px;
                                    transform: scale(0.82);
                                    display: inline-block;
                                }

                                color: #F0364E;
                                font-size: 28px;
                                font-weight: blod;
                            }

                            /* .cmRght_footer {
                            text-align: left;
                            font-size: 10px;
                            transform: scale(0.82);
                            margin-left: -8px;
                        } */
                        }

                        .mbcg_cmCenter {
                            width: 200px;
                            /* text-align: center; */

                            .mbcgc_title {
                                font-size: 13px;
                                color: #000;
                                margin-top: 13px;
                                margin-left: 11px;
                                margin-bottom: 6px;
                            }

                            .mbcgc_time {
                                font-size: 11px;
                                color: #493E2F;
                                transform: scale(0.82);
                            }
                        }

                        .mbcg_cmLeft {
                            span {
                                width: 12px;
                                display: inline-block;
                                transform: scale(0.75);
                            }

                            text-align:center;
                            width: 42px;
                            height: 92%;
                            margin-top: 8px;
                            font-size: 12px;
                            color: #FFFFFF;
                        }
                    }


                }

                .mbcg_Card2 {
                    display: flex;
                    margin-top: 10px;
                    align-items: center;
                    /* overflow-x: scroll; */
                    /* min-width: 480px; */
                    /* overflow-y: overlay; */

                    .mbcg_Cardmain {
                        position: relative;
                        margin-right: 10px;
                        width: 212px;
                        z-index: 1;
                        display: flex;
                        height: 63px;
                        align-items: center;

                        img {
                            width: 212px;
                            height: 63px;
                            position: absolute;
                            z-index: -1;
                        }

                        .mbcg_cmRight {
                            width: 115px;
                            text-align: center;
                            color: #7F7F7F;
                            font-weight: 500;

                            .mbcg_reduction {
                                color: #7F7F7F;
                                font-size: 8px;
                                transform: scale(0.73);
                                margin-top: 5px;

                                text-align-last: justify;
                                /*  这行必加，兼容ie浏览器 */
                                text-justify: distribute-all-lines;
                                text-align: justify;


                            }

                            .mbcg_withAmount {
                                margin-top: -5px;
                                display: inline-block;
                            }

                            .cmRght_top {
                                span {
                                    font-size: 10px;
                                    margin-right: -3px;
                                    transform: scale(0.82);
                                    display: inline-block;
                                }

                                color: #7F7F7F;
                                font-size: 28px;
                                font-weight: blod;
                            }

                            /* .cmRght_footer {
                            text-align: left;
                            font-size: 10px;
                            transform: scale(0.82);
                            margin-left: -8px;
                        } */
                        }

                        .mbcg_cmCenter {
                            width: 200px;
                            text-align: center;

                            .mbcgc_title {
                                font-size: 13px;
                                color: #000;
                                margin-top: 13px;
                                margin-left: 11px;
                            }

                            .mbcgc_time {
                                font-size: 11px;
                                color: #493E2F;
                                transform: scale(0.82);
                            }

                        }

                        .mbcg_cmLeft {
                            span {
                                width: 12px;
                                display: inline-block;
                                transform: scale(0.75);
                            }

                            text-align:center;
                            width: 42px;
                            height: 92%;
                            font-size: 12px;
                            color: #FFFFFF;
                        }
                    }
                }
            }


        }

        .servememcolor {
            color: #F0364E !important;
        }

        .servememcolor2 {
            color: #493E2F !important;
        }

    }

    .van-tabs__line {
        background: none !important;
        background-color: none !important;
    }

    .mbc_serveBox {
        padding-bottom: 50px;
        padding-top: 18px;

        .mbc_serveBottom {
            padding: 0 11px;
            /* height: 135px; */
            margin-bottom: 7px;

            img {
                width: 100%;
                /* height: 135px; */
            }
        }
    }









    .memberFooter {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        background: #44495E;
        width: 100%;
        z-index: 1;
    }

    .memberFooter .member_pay {
        width: 85%;
        text-align: center;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #242430;
        font-size: 18px;
        padding: 12px 0;
        margin: 10px 30px;
        background-image: linear-gradient(#ECBD83, #F8D9AD);
        border-radius: 26px;
    }


    /* 猜你喜欢 */
    .like_listTitle {
        text-align: center;
        margin-top: 23px;

        img {
            width: 117px;
            height: auto;
        }
    }

    .like_list {
        margin-top: 22px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 70px;
        /* padding: 0 2.5px; */
        box-sizing: border-box;
        justify-content: space-between;
    }


    .like_item_warp {
        width: 50%;
        /* width: 174px; */
        box-sizing: border-box;
        /* margin: 0px 7px 7px 0; */
        margin-bottom: 7px;
    }

    .like_item_warp:nth-child(2n) .like_item {
        /* margin: 0px 0px 7px 0; */
        /* margin-left: 7px;
		margin-bottom: 7px; */
        margin-left: 7px;
    }

    .like_item {
        box-sizing: border-box;
        /* border-radius: 4px; */
        background-color: #fff;
        /* margin: 0 7px 5px; */
    }

    .like_item:nth-child(2n) {
        margin: 0px 0px 7px 0;
    }

    /* .like_item:nth-child(even) {
    margin-right: 0;
    margin-left: 0;
	} */

    .like_item-imgbox {
        height: 148px;
        position: relative;
    }

    .like_item-img {
        width: 105px;
        height: 121px;
        position: absolute;
        top: 20px;
        left: 50%;
        margin-left: -52.5px;
    }

    .like_item-info {
        padding: 10px 12px 12px 12px;
    }

    .like_item-name {
        min-height: 38px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-family: PingFangSC-Semibold;
        color: #201F1F;
    }

    .like_item-people {
        padding-top: 10px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        font-family: PingFangSC-Semibold;
    }

    .like_item-price {
        color: #F0374D;
        font-size: 16px;
        font-weight: bold;
        font-family: Arial-BoldMT, Arial;
        display: flex;
        align-items: baseline;
    }

    .rmb {
        color: #F0374D;
        font-size: 12px;
        font-weight: bold;
        font-family: Arial-BoldMT, Arial;
    }

    .lick_black {
        background-color: #000000;
        padding: 0 3px;
        color: #E09B6C;
        transform: scale(0.82);
        font-size: 10px;
        margin-left: 4px;
        /* margin-right: 2px; */
    }

    .rmb2 {
        transform: scale(0.7);
        color: #000000;
        font-size: 7px;
        font-weight: bold;
        font-family: Arial-BoldMT, Arial;
    }

    .black_rmb {
        /* transform: scale(0.9); */
        margin-left: -3px;
        font-size: 11px;
        color: #000000;
    }

    .like_item-play {
        color: #bcbcbc;
        font-size: 10px;
        margin-top: 5px;
    }

    #cnzz_stat_icon_1279074062 {
        opacity: 0;
        position: relative;
        left: -100px;
    }


    .van-tabs__nav--line .van-tab .van-tab__text span {
        color: #cc0000 !important;
        display: flex;
    }

    .van-tabs__nav--line .van-tab .van-tab__text span {
        height: 30px !important;
        color: #000;
        display: flex !important;
        color: #cc0000 !important;
    }


    .selectTab /deep/ .van-tabs__wrap {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;
    }

    .selectTab /deep/ .van-tabs__nav--line {
        width: 100%;
        display: flex;

        justify-content: space-between;
    }

    .selectTab /deep/ .van-tab {
        /* width: 100%; */
        display: flex;
        justify-content: space-between;
        flex: none;
    }


    div /deep/ .van-tab {
        padding: 0;
    }
</style>