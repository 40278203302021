<template>
    <div class="agree">
        <p class="agree_title">{{rule.title}}</p>
        <p class="agree_center" v-html="rule.content"></p>
    </div>
</template>
<script>
    import { getCommodityAgreementByType } from "@/api/activity";
    export default {
        inject: ["reload"],
        components: {
            // Swiper,
            // SwiperSlide,
        },
        data() {
            return {
                rule: ""
            };
        },

        methods: {
            getCommodityAgreementByType() {
                getCommodityAgreementByType({ status: 15 }).then(async res => {
                    if (res.code === "200") {
                        console.log("Res", res);
                        this.rule = res.data;
                    }
                });
            }
        },
        mounted() {
            this.getCommodityAgreementByType();
        }
    };

</script>
<style>
    body {
        box-sizing: border-box;

    }

    img {
        width: 100%;
        height: auto;
    }

    .agree {
        background: #fff;
        padding: 15px;
        /* width: 100%; */
    }

    .agree_title {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }

    .agree_center {
        margin-top: 15px;
    }
</style>