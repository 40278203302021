const api = {
    getActivityInfo: "/mk/app/collectionActivity/getActivityDetail",
    onekeyCollection: "/mk/user/membership/onekeyCollection",
    getTakeStatusByBadId: "/mk/app/commodityCouponGiftBag/getTakeStatusByBadId",
    getHotRecommendations: "/mk/app/commoditySingleProduct/getHotRecommendations",
    getCommodityMemberships: "/mk/user/membership/getCommodityMemberships",
    getMyCommodityMemberships: "/mk/user/membership/getMyCommodityMemberships",
    getCommodityAgreementByType: "/mk/app/commodityAgreement/getCommodityAgreementByType",
};

export default api;
