import api from "./index";
import { axios } from "@/utils/request";
export function getActivityInfo(parameter) {
    return axios({
        url: api.getActivityInfo,
        method: "post",
        data: parameter
    });
}

export function onekeyCollection(parameter) {
    return axios({
        url: api.onekeyCollection,
        method: "post",
        data: parameter
    });
}

export function getTakeStatusByBadId(parameter) {
    return axios({
        url: api.getTakeStatusByBadId,
        method: "post",
        data: parameter
    });
}

export function getHotRecommendations(parameter) {
    return axios({
        url: api.getHotRecommendations,
        method: "post",
        data: parameter
    });
}
export function getCommodityMemberships(parameter) {
    return axios({
        url: api.getCommodityMemberships,
        method: "post",
        data: parameter
    });
}
export function getMyCommodityMemberships(parameter) {
    return axios({
        url: api.getMyCommodityMemberships,
        method: "post",
        data: parameter
    });
}

export function getCommodityAgreementByType(parameter) {
    return axios({
        url: api.getCommodityAgreementByType,
        method: "post",
        data: parameter
    });
}


